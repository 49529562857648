import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client"; //useMutation, ApolloError
import { UilCaretRight } from "@iconscout/react-unicons";
import { useLocation } from "react-router-dom";
import { RouteLocation } from 'common/types';
import {
  Card,
  Select,
  SelectOptionType,
  MONTHS,
  WEEKDAYS_LONG_MONDAY,
} from "ui-components";
import GET_MYSELF from "common/queries/mySelf.query";
import { DAY_REPORTS_QUERY } from "./MonthlyReport.queries";
import {
  formatDouble,
  getYearsArray,
  getDateKey,
  getLastDayOfMonth,
  parseSearchParams,
} from "common/utils";
import MonthlyReportDay from "./MonthlyReportDay";
import "./MonthlyReport.scss";

const yearsArray = getYearsArray(5, 1);

const MonthlyReport = () => {
  const { search } = useLocation<RouteLocation>();
  const today = new Date();
  const [uid, setUid] = useState<string>();
  const [selectedMonth, setMonth] = useState(today.getMonth());
  const [selectedYear, setYear] = useState(today.getFullYear());
  const [roomsAvailable, setRoomsAvailable] = useState<number>();
  // const [roomsUpdated, setRoomsUpdated] = useState(false);
  const [yearsOptions] = useState(
    yearsArray.map(
      (year: number): SelectOptionType => ({
        name: year.toString(),
        label: year.toString(),
      })
    )
  );
  const [daysArray, setDaysArray] = useState(
    new Array(getLastDayOfMonth(selectedYear, selectedMonth)).fill(0)
  );
  const [emptyDays, setEmptyDays] = useState(
    Array(today.getDay() === 0 ? 6 : today.getDay() - 1).fill(0)
  );
  // const [modifiers, setModifiers] = useState<Partial<Modifiers>>({
  //   selected: [],
  // });
  const [mappedDailyReports, setMappedDaily] = useState<Record<string, any>>(
    {}
  );
  const { data: mySelfData } = useQuery(GET_MYSELF);
  const { data: dailyData, refetch } = useQuery(DAY_REPORTS_QUERY, {
    skip: !mySelfData?.user?.hotel?._id && !uid,
    variables: {
      year: selectedYear,
      month: selectedMonth,
      from: {
        year: selectedMonth <= 0 ? selectedYear - 1 : selectedYear,
        month: selectedMonth <= 0 ? 11 : selectedMonth - 1,
        day: 29,
      },
      to: {
        year: selectedMonth >= 11 ? selectedYear + 1 : selectedYear,
        month: selectedMonth >= 11 ? 0 : selectedMonth + 1,
        day: 2,
      },
      hotelIds: [uid || mySelfData?.user?.hotel?._id],
      hotelId: uid || mySelfData?.user?.hotel?._id,
      uid,
    },
  });

  // const [updateRoomsMutation] = useMutation(UPDATE_ROOMS, {
  //   onCompleted: () => {
  //     refetch();
  //     setRoomsUpdated(false);
  //     // alert("Pakeitimai išsaugoti!");
  //   },
  //   onError: (error: ApolloError) => console.error(error),
  // });

  useEffect(() => {
    if (search) {
      const params = parseSearchParams(search);
      if (params.year && params.month) {
        setYear(parseInt(params.year));
        setMonth(parseInt(params.month));
      }
      if (params.uid) {
        setUid(params.uid);
      }
    }
  }, [search]);

  useEffect(() => {
    if (!dailyData) {
      return;
    }
    const mapped = dailyData.dailyReports.reduce(
      (acc: Record<string, any>, report: any) => ({
        ...acc,
        [getDateKey(report.year, report.month, report.day)]: report,
      }),
      {}
    );

    // setModifiers({
    //   selected: dailyData.dailyReports.map(
    //     (report: any) => new Date(report.year, report.month, report.day)
    //   ),
    // });
    setMappedDaily(mapped);
  }, [dailyData]);

  useEffect(() => {
    const weekDay = new Date(selectedYear, selectedMonth, 1).getDay();
    setEmptyDays(Array(weekDay === 0 ? 6 : weekDay - 1).fill(0));
    const lastDay = getLastDayOfMonth(selectedYear, selectedMonth);
    setDaysArray(Array(lastDay).fill(0));
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    // if (dailyData?.hotelSingleMonthReport?.roomsAvailable) {
    //   setRoomsAvailable(
    //     Math.round(dailyData?.hotelSingleMonthReport?.roomsAvailable)
    //   );
    // } else 
    if (mySelfData?.user?.hotel?.numberOfRooms) {
      setRoomsAvailable(mySelfData?.user?.hotel?.numberOfRooms);
    }
  }, [mySelfData, dailyData]);

  const yearChangeHandler = useCallback(
    (newValue: string | number) =>
      setYear(typeof newValue === "string" ? parseInt(newValue) : newValue),
    []
  );

  const monthSubstractHandler = useCallback(() => {
    if (selectedMonth === 0) {
      setYear(selectedYear - 1);
      setMonth(11);
    } else {
      setMonth(selectedMonth - 1);
    }
  }, [selectedMonth, selectedYear]);

  const monthAddHandler = useCallback(() => {
    if (selectedMonth === 11) {
      setYear(selectedYear + 1);
      setMonth(0);
    } else {
      setMonth(selectedMonth + 1);
    }
  }, [selectedMonth, selectedYear]);

  // const changeRoomsHandler = useCallback((name: string, value: string) => {
  //   setRoomsAvailable(parseInt(value));
  //   setRoomsUpdated(true);
  // }, []);

  // const blurRoomsHandler = useCallback(() => {
  //   if (roomsUpdated) {
  //     updateRoomsMutation({
  //       variables: {
  //         hotelId: mySelfData?.user?.hotel?._id,
  //         year: selectedYear,
  //         month: selectedMonth,
  //         roomsAvailable,
  //       },
  //     });
  //   }
  // }, [
  //   roomsUpdated,
  //   roomsAvailable,
  //   mySelfData,
  //   selectedMonth,
  //   selectedYear,
  //   updateRoomsMutation,
  // ]);

  return (
    <div className='ph-l'>
      <div className='flexRow'>
        <div className='MonthlyReport-content'>
          <Card className='MonthlyReport-summary mb-m flexColumn'>
            <div className='MonthlyReport-summaryHeader mb-m'>
              Mėnesio suvestinė
            </div>
            <div className='MonthlyReport-summaryControls mb-m'>
              <div>
                <Select
                  options={yearsOptions}
                  selected={selectedYear.toString()}
                  onChange={yearChangeHandler}
                  plain
                />
              </div>
              <div className='MonthlyReport-summaryMonth'>
                {MONTHS[selectedMonth]}
              </div>
              <div className='flexRow'>
                <div
                  className='MonthlyReport-summaryButton pr-xs rotate'
                  onClick={monthSubstractHandler}
                >
                  <UilCaretRight size={18} />
                </div>
                <div
                  className='MonthlyReport-summaryButton pr-xs'
                  onClick={monthAddHandler}
                >
                  <UilCaretRight size={18} />
                </div>
              </div>
            </div>
            <div className='MonthlyReport-summaryContent'>
              <div className='mr-l flexRow'>
                <div className='highlighted mr-xs'>Užimtumas:</div>
                <div>
                  {`${formatDouble(
                    dailyData?.hotelSingleMonthReport?.occupancy
                  )} %`}
                </div>
              </div>
              <div className='mr-l flexRow'>
                <div className='highlighted mr-xs'>ADR:</div>
                <div>
                  {formatDouble(
                    dailyData?.hotelSingleMonthReport?.averageDailyRate
                  )}
                </div>
              </div>
              <div className='flexRow'>
                <div className='highlighted mr-xs'>RevPAR:</div>
                <div>
                  {formatDouble(dailyData?.hotelSingleMonthReport?.revPAR)}
                </div>
              </div>
            </div>
            <div className='MonthlyReport-roomsRow mt-m'>
              <div className='highlighted mr-s'>Turimi kambariai: {roomsAvailable}</div>
              {/* <div className='MonthlyReport-roomsInput'>
                <InputText
                  value={roomsAvailable}
                  type='number'
                  name='roomsAvailable'
                  noMargins
                  onChange={changeRoomsHandler}
                  onBlur={blurRoomsHandler}
                />
              </div> */}
            </div>
          </Card>
          <div className='MonthlyReport-calendarContainer'>
            {WEEKDAYS_LONG_MONDAY.map((day, dayIndex) => (
              <div
                key={`empty_${dayIndex}`}
                className='mr-s mb-s MonthlyReport-calendarDayEmpty highlighted'
              >
                {day}
              </div>
            ))}
            {emptyDays.map((a, index) => (
              <div
                key={`empty_${index}`}
                className='mr-s mb-s MonthlyReport-calendarDayEmpty'
              >
                {" "}
              </div>
            ))}
            {daysArray.map((a, index) => {
              const dateKey = getDateKey(
                selectedYear,
                selectedMonth,
                index + 1
              );
              return (
                <MonthlyReportDay
                  key={dateKey}
                  year={selectedYear}
                  month={selectedMonth}
                  day={index + 1}
                  data={mappedDailyReports[dateKey]}
                  roomsAvailable={roomsAvailable}
                  hotelId={uid || mySelfData?.user?.hotel?._id}
                  refetchQuery={refetch}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyReport;
