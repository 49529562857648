import React, { useState, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Input, Overlay } from "ui-components";
import { tokenState } from 'state/session';
import { getUri } from 'network-settings';
import './Users.scss';

type CreateUsersProps = {
  onClose: (update: boolean) => void;
};

const CreateUsers: React.FC<CreateUsersProps> = ({ onClose }) => {
  const [user, setUser] = useState("");
  const token = useRecoilValue(tokenState);
  const password = 'nesvarbu';

  const overlayClickHandler = useCallback(() => onClose(false), [onClose]);
  // TODO: add validation
  const userChangeHandler = useCallback((value: string) => setUser(value), []);
  const createUserHandler = useCallback(async () => {
    const uriRoot = getUri();
    await fetch(`${uriRoot}/users`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json", 
      },
      body: JSON.stringify({
        authentication: {
          accessToken: token,
          strategy: 'jwt',
        },
        email: user,
        password,
      }),
    });
    
    setUser('');
    onClose(true);
  }, [password, user, token, onClose]);
  return (
    <>
      <Overlay onClick={overlayClickHandler} />
      <div className='ph-m Users-create'>
          <div className='mb-m'>
            Sukurti naują prisijungimą
          </div>
          <div className='mb-m'>
            <Input
              value={user}
              type='email'
              onChange={userChangeHandler}
              placeholder='el. paštas'
            />
          </div>
          <div className='Users-submitContainer'>
            <Button onClick={createUserHandler} color='primary'>
              Registruoti
            </Button>
          </div>
      </div>
    </>
  );
};

export default CreateUsers;
