import { gql } from '@apollo/client';

export default gql`
query GetMyself {
  user(_id: "myself") {
    _id
    email
    isAdmin
    hotel {
      _id
      name
      stars
      numberOfRooms
    }
    dashboardFilters {
      town
      hotelType
      serviceTypes
      stars
      selectedHotelNames
    }
  }
}`;