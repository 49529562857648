import React, { useState, useCallback } from "react";
import { useQuery, useMutation, ApolloError } from "@apollo/client";
import { Card } from "ui-components";
import ListItem from "./UsersListItem";
import UsersCreate from "./UsersCreate";
import UserHotel from './UserHotel';
import { GET_USERS, REMOVE_USER } from './Users.queries';
import "./Users.scss";

const Users: React.FC = () => {
  const { data, refetch } = useQuery(GET_USERS);
  const [showCreateUser, setShowCreate] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [removeUserMutation] = useMutation(REMOVE_USER, {
    onCompleted: () => {
      refetch();
    },
    onError: (error: ApolloError) => console.error(error),
  });

  const closeCreateUser = useCallback(() => {
    setShowCreate(false);
    refetch();
  }, [refetch]);
  const openCreateUser = useCallback(() => setShowCreate(true), []);
  const removeUser = useCallback((id: string) => {
    removeUserMutation({
      variables: {
        id,
      },
    })
  }, [removeUserMutation]);
  const selectUserHandler = useCallback((id: string) => {
    setSelectedUser(id);
  }, []);

  if (!data) {
    return null;
  }
  return (
    <div className='Users-list ph-l pb-l'>
      <Card pushDown style={{minWidth: '466px', maxHeight: `${window.innerHeight - 140}px`, overflowY: 'scroll'}}>
        <div className='Users-listHeader  mb-m'>
          <div className='Users-ListHeaderText'>Naudotojai</div>
          <div className='Users-listHeaderButton' onClick={openCreateUser}>
            +
          </div>
        </div>
        {data.users.map((user: any) => (
          <ListItem user={user} key={user._id} onClick={selectUserHandler} removeUser={removeUser} />
        ))}
      </Card>
      {selectedUser ? (
        <div className='ml-m'>
          <UserHotel
            userId={selectedUser}
            userDetails={data?.users?.find((user: any) => user._id === selectedUser)}
            refetchList={refetch}
          />
        </div>
      ) : null}
      {showCreateUser ? <UsersCreate onClose={closeCreateUser} /> : null}
    </div>
  );
};

export default Users;
