import React from 'react';
import { Card } from 'ui-components';
import { MONTHS_WHO } from "ui-components";

type EventsDayHeaderProps = {
  day: Date
};

const EventsDayHeader: React.FC<EventsDayHeaderProps> = (props) => {
  if (!props.day) {
    return <Card>Diena</Card>
  }
  const year = props.day.getFullYear();
  const monthIndex = props.day.getMonth();
  const dayNumber = props.day.getDate();
  
  return (
    <Card>
      <div className='Events-dayHeader'>
        {`${year} ${MONTHS_WHO[monthIndex]} ${dayNumber}`}
      </div>
    </Card>
  )
};

export default EventsDayHeader;
