import React from "react";
import { FormFieldType, StarsSelect, InputProps } from "ui-form";
import { OptionItemType } from "../Settings/SettingsOptionsCard";

export const getFields = (
  hotelTypes: OptionItemType[],
  serviceTypes: OptionItemType[],
  towns: OptionItemType[]
): FormFieldType[] => [
  {
    label: "Tipas",
    name: "hotelType",
    type: "multiSelect",
    options: hotelTypes
      ? hotelTypes.map((type: OptionItemType) => ({
          name: type.type,
          label: type.label,
        }))
      : [],
  },
  {
    label: "Žvaigždutės",
    name: "stars",
    type: "custom",
    component: (props: InputProps) => <StarsSelect isMulti {...props} />,
  },
  {
    label: "Miestas",
    name: "town",
    type: "multiSelect",
    options: towns
      ? towns.map((type: OptionItemType) => ({
          name: type.type,
          label: type.label,
        }))
      : [],
  },
  {
    label: "Paslaugos",
    name: "serviceTypes",
    type: "multiSelect",
    options: serviceTypes
      ? serviceTypes.map((type: OptionItemType) => ({
          name: type.type,
          label: type.label,
        }))
      : [],
  },
];
