import React from "react";
import DayPicker, { DayPickerProps } from "react-day-picker";
import "react-day-picker/lib/style.css";
import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  FIRST_DAY_OF_WEEK,
  LABELS,
} from "./calendarTranslations";

export const Calendar: React.FC<DayPickerProps> = (props) => (
  <DayPicker
    locale='lt'
    months={MONTHS}
    weekdaysLong={WEEKDAYS_LONG}
    weekdaysShort={WEEKDAYS_SHORT}
    firstDayOfWeek={FIRST_DAY_OF_WEEK}
    labels={LABELS}
    {...props}
  />
);
