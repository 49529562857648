import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery, useMutation, ApolloError } from "@apollo/client";
import { Link } from "react-router-dom";
import { UilPlus } from "@iconscout/react-unicons";
import { Card, Select, Button } from "ui-components";
import { DashboardFilterType } from "./Dashboards.types";
import {
  getYearsOptions,
  monthsOptions,
  mapByYearMonth,
  getChartYearMonths,
  // getPreviousPeriod,
  getPeriodString,
} from "common/utils";
import GET_MYSELF from "common/queries/mySelf.query";
import { MONTHLY_REPORT, REMOVE_FILTER, RECALCULATE_ALL_REPORTS } from "./dashboard.queries";
import AddFilter from "./DashboardAddFilter";
import DashboardDataRow from "./DashboardDataRow";
import DashboardChart from "./DashboardChart";
import { excelExport } from './excelExport2';
import "./Dashboard.scss";

const Dashboard = () => {
  const today = new Date();
  const yearsOptions = getYearsOptions(today.getFullYear(), 3);
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());
  const [myHotel, setMyHotel] = useState<any>(null);
  const [myHotelPrev, setMyHotelPrev] = useState<any>(null);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filteredDataPrev, setFilteredDataPrev] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [chartWidth, setChartWidth] = useState(0);
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [clickCounter, setClickCounter] = useState(0);
  const ref = useRef<HTMLHeadingElement>(null);
  const { data: mySelfData, refetch: refetchMySelf } = useQuery(GET_MYSELF);
  const { data: myHotelData, refetch: refetchReports } = useQuery(
    MONTHLY_REPORT,
    {
      skip: !mySelfData?.user,
      variables: {
        from: { year: year, month: 0 },
        to: { year, month },
        userId: mySelfData?.user?._id,
      },
    }
  );
  const { data: myHotelPrevData, refetch: refetchPrevReports } = useQuery(
    MONTHLY_REPORT,
    {
      skip: !mySelfData?.user,
      variables: {
        from: { year: year - 1, month: 0 },
        to: { year: year - 1, month },
        userId: mySelfData?.user?._id,
      },
    }
  );
  const [removeFilterQuery] = useMutation(REMOVE_FILTER, {
    onCompleted: () => {
      refetchMySelf();
      refetchReports();
      refetchPrevReports();
    },
    onError: (error: ApolloError) => console.error(error),
  });
  const [updateAllReportsMutation] = useMutation(RECALCULATE_ALL_REPORTS);

  useEffect(() => {
    
    const ownMapped = myHotelData?.hotelMonthReport?.own
      ? mapByYearMonth(myHotelData.hotelMonthReport.own)
      : {};
    const filteredMapped = myHotelData?.hotelMonthReport?.byFilters
      ? myHotelData.hotelMonthReport.byFilters.map((items: any) =>
          mapByYearMonth(items)
        )
      : [];
    const chartYaxis = getChartYearMonths(year, month, true).map((name: string) => {
      const ownForMonth = ownMapped[name] || {};
      const filteredData = filteredMapped.reduce(
        (acc: Record<string, any>, data: any, index: number) => {
          if (data[name]) {
            return {
              ...acc,
              [`occupancy_${index}`]:
                data[name].occupancy && data[name].occupancy,
              [`averageDailyRate_${index}`]: data[name].averageDailyRate,
              [`revPAR_${index}`]: data[name].revPAR,
            };
          }
          return acc;
        },
        {}
      );
      return { name, ...ownForMonth, ...filteredData };
    });
    setChartData(chartYaxis);
  }, [myHotelData, year, month]);

  useEffect(() => {
    if (myHotelData?.hotelMonthReport?.own?.length > 0) {
      const lastOwnReport =
        myHotelData.hotelMonthReport.own[
          myHotelData.hotelMonthReport.own.length - 1
        ];
      if (
        lastOwnReport &&
        lastOwnReport.year === year &&
        lastOwnReport.month === month
      ) {
        setMyHotel(lastOwnReport);
        
      } else {
        setMyHotel(null);
      }
    } else {
      setMyHotel(null);
    }
    setFilteredData(
      myHotelData?.hotelMonthReport.byFilters.map((item: any) => {
        const lastReport = item[item.length - 1];
        return lastReport &&
          lastReport.year === year &&
          lastReport.month === month
          ? lastReport
          : null;
      }) || []
    );
  }, [myHotelData, year, month]);

  useEffect(() => {
    setFilteredDataPrev(
      myHotelPrevData?.hotelMonthReport?.byFilters.map((item: any) => {
        const lastReport = item[item.length - 1];
        return lastReport &&
          lastReport.year === year - 1 &&
          lastReport.month === month
          ? lastReport
          : null;
      }) || []
    );

    const ownLastReport =
      myHotelPrevData?.hotelMonthReport?.own[
        myHotelPrevData?.hotelMonthReport?.own.length - 1
      ];
    setMyHotelPrev(
      ownLastReport &&
        ownLastReport.year === year - 1 &&
        ownLastReport.month === month
        ? ownLastReport
        : null
    );
  }, [myHotelPrevData, year, month]);

  useEffect(() => {
    setChartWidth(ref.current ? (ref.current.offsetWidth - 130) / 3 : 0);
  }, [ref]);

  const openAddFilter = useCallback(() => {
    setShowAddFilter(true);
  }, []);

  const closeAddFilter = useCallback(
    (doRefetch) => {
      setShowAddFilter(false);
      if (doRefetch) {
        refetchMySelf();
        refetchReports();
        refetchPrevReports();
      }
    },
    [refetchMySelf, refetchReports, refetchPrevReports]
  );

  const yearSelectHandler = useCallback((value: string | number) => {
    setYear(typeof value === "string" ? parseInt(value) : value);
  }, []);

  const monthSelectHandler = useCallback((value: string | number) => {
    setMonth(typeof value === "string" ? parseInt(value) : value);
  }, []);

  const removeFilter = (index: number) => {
    removeFilterQuery({
      variables: {
        user: mySelfData.user?._id,
        index,
      },
    });
  };

  const exportExcelHandler = useCallback(() => {
    excelExport(
      mySelfData?.user?.isAdmin ? "Visi viešbučiai" : mySelfData?.user?.hotel?.name,
      year,
      month,
      chartData,
      mySelfData?.user?.dashboardFilters || [],
      mySelfData?.user?._id,
    );
    // alert("Funkcionalumas kuriamas");
  }, [mySelfData, year, month, chartData]);

  const handleFiltersNameClick = () => {
    if (clickCounter < 4) {
      setClickCounter(clickCounter + 1);
      return;
    }
    updateAllReportsMutation({
      variables: {
        year,
        month
      }
    });
    setClickCounter(0);
  }

  const hotelName = mySelfData?.user?.isAdmin ? "Visi viešbučiai" : mySelfData?.user?.hotel?.name;

  return (
    <div className='ph-l pb-l flexColumn'>
      <div className='mb-m flexRow'>
        <div>
          <Select
            options={yearsOptions}
            selected={year}
            onChange={yearSelectHandler}
          />
        </div>
        <div className='ml-s'>
          <Select
            options={monthsOptions}
            selected={month}
            onChange={monthSelectHandler}
          />
        </div>
        <div className='ml-m'>
          <Button
            onClick={exportExcelHandler}
            className='Dashboard-exportButton'
          >
            EXPORTUOTI DUOMENIS
          </Button>
        </div>
      </div>
      <div className='mb-m flexRow Dashboard-chartsContainer' ref={ref}>
        <DashboardChart
          label='Užimtumas %'
          chartWidth={chartWidth}
          hotelName={hotelName}
          dataKey='occupancy'
          dashboardFilters={mySelfData?.user?.dashboardFilters || []}
          chartData={chartData}
        />
        <DashboardChart
          label='Vidutinė pardavimo kaina (ADR)'
          chartWidth={chartWidth}
          hotelName={hotelName}
          dataKey='averageDailyRate'
          dashboardFilters={mySelfData?.user?.dashboardFilters || []}
          chartData={chartData}
        />
        <DashboardChart
          label='Vidutinės pajamos už kambarį (RevPAR)'
          chartWidth={chartWidth}
          hotelName={hotelName}
          dataKey='revPAR'
          dashboardFilters={mySelfData?.user?.dashboardFilters || []}
          chartData={chartData}
        />
      </div>
      <Card pushDown className='flexColumn'>
        <div className='flexRow pt-m ph-m'>
          <div className='Dashboard-listHeader filterName' onClick={handleFiltersNameClick}>
            Filtrai
          </div>
          <div className='Dashboard-listHeader Dashboard-ListNumberColumn'>
            Užimtumas %
          </div>
          <div className='Dashboard-listHeader Dashboard-ListNumberColumn'>
            ADR
          </div>
          <div className='Dashboard-listHeader Dashboard-ListNumberColumn'>
            RevPER
          </div>
          <div className='Dashboard-listHeader Dashboard-ListNumberColumn Dashboard-ListNumberColumn--small'>
            Viešbučiai viso
          </div>
          <div className='Dashboard-listHeader Dashboard-ListNumberColumn Dashboard-ListNumberColumn--small'>
            Viešbučiai pateikė
          </div>
        </div>
        <DashboardDataRow
          name={hotelName}
          filteredData={myHotel}
          prevData={myHotelPrev}
        />
        {myHotelData?.hotelMonthReport?.hasReported  && mySelfData?.user?.dashboardFilters
          ? mySelfData.user.dashboardFilters.map(
              (filter: DashboardFilterType, index: number) => (
                <DashboardDataRow
                  key={`filter_row_${index}`}
                  index={index}
                  filter={filter}
                  removeFilter={removeFilter}
                  filteredData={filteredData && filteredData[index]}
                  prevData={filteredDataPrev && filteredDataPrev[index]}
                />
              )
            )
          : null}
        {myHotelData?.hotelMonthReport || mySelfData?.user?.isAdmin ?
          myHotelData?.hotelMonthReport?.hasReported || mySelfData?.user?.isAdmin ?
            (<div className='mt-m flexRow'>
              <Card className='Dashboard-addFilter' onClick={openAddFilter}>
                <UilPlus size={18} color='#5D7C78' />
              </Card>
            </div>) :
        (
          <>
            <Card className='mt-m'>
              <div className='Dasboard-noReportWarning'>
                {`Norint lyginti kainos su kitais viešbučiais turite suvesti pilnai duomenis už laikotarpius: ${getPeriodString(year, month)}`}
              </div>
            </Card>
            <div  className='mt-m flexRow'>
              <Link to={`/monthlyReports?year=${year}&month=${month}`} className='Dashboard-link'>
                <Card className='Dashboard-addFilter'>
                  Pildyti Ataskaitas
                </Card>
              </Link>
            </div>
          </>
        ) : null}
      </Card>
      {showAddFilter ? <AddFilter onClose={closeAddFilter} /> : null}
    </div>
  );
};

export default Dashboard;
