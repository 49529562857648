import React, { useCallback } from "react";
import { Card, Tooltip } from "ui-components";
import { UilTrash } from "@iconscout/react-unicons";
import {
  formatDouble,
  formatPercentage,
  getDiffPercentage,
} from "common/utils";
import { getFilterName } from "./dashboard.utils";
import { DashboardDataRowType } from "./Dashboards.types";

const DashboardDataRow: React.FC<DashboardDataRowType> = ({
  index,
  name,
  filter,
  removeFilter,
  filteredData,
  prevData,
}) => {
  const removeHandler = useCallback(() => {
    if (removeFilter && index !== undefined) {
      removeFilter(index);
    }
  }, [index, removeFilter]);
  const displayName = name ? name : filter ? getFilterName(filter, index) : "";
  return (
    <Card className='flexRow mt-m' key={`filter_row_${index}`}>
      <div className='Dashboard-listCell flexRow'>
        <div className='Dashboard-listLineName'>
          <Tooltip text={displayName}>
            {displayName}
          </Tooltip>
        </div>
      </div>
      {removeFilter ? (
        <div className='Dashboard-listTrash ph-s' onClick={removeHandler}>
          <UilTrash size={18} color='#5D7C78' />
        </div>
      ) : null}
      <div className='Dashboard-ListNumberColumn'>
        {formatDouble(filteredData?.occupancy)}
        <Tooltip text={`Pokytis nuo praėjusių metų procentais. Buvo ${formatDouble(prevData?.occupancy)}`}>
          (
          {formatPercentage(prevData?.occupancy && filteredData?.occupancy ? 
            getDiffPercentage(
              prevData?.occupancy,
              filteredData?.occupancy
            ): null
          )}{" "}
          %)
        </Tooltip>
      </div>
      <div className='Dashboard-ListNumberColumn'>
        {formatDouble(filteredData?.averageDailyRate)}
        <Tooltip text={`Pokytis nuo praėjusių metų procentais. Buvo ${formatDouble(prevData?.averageDailyRate)}`}>
          (
          {formatPercentage(prevData?.averageDailyRate && filteredData?.averageDailyRate ? 
            getDiffPercentage(
              prevData?.averageDailyRate,
              filteredData?.averageDailyRate
            ): null
          )}{" "}
          %)
        </Tooltip>
      </div>
      <div className='Dashboard-ListNumberColumn'>
        {formatDouble(filteredData?.revPAR)}
        <Tooltip text={`Pokytis nuo praėjusių metų procentais. Buvo ${formatDouble(prevData?.revPAR)}`}>
          (
          {formatPercentage(prevData?.revPAR && filteredData?.revPAR ? 
            getDiffPercentage(
              prevData?.revPAR,
              filteredData?.revPAR
            ): null
          )}{" "}
          %)
        </Tooltip>
      </div>
      <div className='Dashboard-ListNumberColumn Dashboard-ListNumberColumn--small'>
        {filteredData?.hotelsFound || ""}
      </div>
      <div className='Dashboard-ListNumberColumn Dashboard-ListNumberColumn--small'>
        {filteredData?.hotelsValidCount ? filteredData?.hotelsValidCount : filteredData?.hotelsReported || ""}
      </div>
    </Card>
  );
};

export default DashboardDataRow;
