import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { UilExclamationTriangle, UilTrash, UilBan } from "@iconscout/react-unicons";
import { Checkbox, Tooltip } from "ui-components";
import { RESEND_VERIFICATION, UPDATE_USERS } from './Users.queries';

type UsersListItemProps = {
  user: {
    _id: string;
    email: string;
    isVerified: boolean;
    isAdmin: boolean;
    isDisabled?: boolean;
  };
  onClick: (id: string) => void;
  removeUser: (id: string) => void;
};

const UsersListItem: React.FC<UsersListItemProps> = ({ user, onClick, removeUser }) => {
  const [updateUserMutation] = useMutation(UPDATE_USERS);
  const [resendVerification] = useMutation(RESEND_VERIFICATION);
  const handleToggle = useCallback(() => {
    updateUserMutation({
      variables: {
        user: {
          _id: user._id,
          isAdmin: !user.isAdmin,
        },
      },
    });
  }, [user, updateUserMutation]);
  const handleResend = useCallback(() => {
    resendVerification({
      variables: {
        id: user._id,
      },
    });
  }, [user, resendVerification]);
  const remove = useCallback(() => removeUser(user._id), [user, removeUser]);
  const clickHandler = useCallback(() => onClick(user._id), [user, onClick])

  return (
    <div className='Users-listItem mb-m p-s' onClick={clickHandler}>
      <div className='Users-listItem--text'>
        {!user.isVerified && (
          <div className='ph-xs mr-s Users-listItemButton' onClick={handleResend}>
            <Tooltip text='Naudotojas nepatvirtino prisijungimo. Paspauskite norėdami pakartotinai išsiųsti laišką'>
              <UilExclamationTriangle color="#cf9125" />
            </Tooltip>
          </div>
        )}
        {user.email}
      </div>
      {!user.isVerified ? (
        <div className='ph-s mr-s Users-listItemButton' onClick={remove}>
          <Tooltip text='Pašalinti naudotoją' position='left'>
            <UilTrash color="#f44336" />
          </Tooltip>
        </div>
      ) : null}
      {user.isDisabled ? (
        <div className='ph-s mr-s Users-listItemButton' style={{paddingTop: '3px'}}>
          <Tooltip text='Naudotojui prisijungimas deaktyvuotas' position='left'>
            <UilBan color="#cf9125" />
          </Tooltip>
        </div>
      ) : null}
      <div>
        <Checkbox value={user.isAdmin} onToggle={handleToggle} />
      </div>
    </div>
  );
};

export default UsersListItem;
