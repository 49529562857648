import React from "react";
import { FormFieldType, StarsSelect, InputProps } from "ui-form";
import { OptionItemType } from "../Settings/SettingsOptionsCard";

export const getFields = (
  hotelTypes: OptionItemType[],
  serviceTypes: OptionItemType[],
  towns: OptionItemType[]
): FormFieldType[] => [
  {
    label: "Pavadinimas",
    name: "name",
    type: "text",
    placeholder: "Viešbučio pavadinimas",
  },
  {
    label: "Tipas",
    name: "type",
    type: "select",
    options: hotelTypes
      ? hotelTypes.map((type: OptionItemType) => ({
          name: type.type,
          label: type.label,
        }))
      : [],
  },
  {
    label: "Žvaigždutės",
    name: "stars",
    type: "custom",
    component: (props: InputProps) => <StarsSelect {...props} />,
  },
  {
    label: "Kambarių skaičius",
    name: "numberOfRooms",
    type: "number"
  },
  {
    label: "Miestas",
    name: "town",
    type: "select",
    placeholder: "Miestas",
    options: towns
      ? towns.map((type: OptionItemType) => ({
          name: type.type,
          label: type.label,
        }))
      : [],
  },
  {
    label: "Adresas",
    name: "address",
    type: "text",
    placeholder: "Pilnas adresas",
  },
  {
    label: "Rajonas",
    name: "neighbourhood",
    type: "text",
    placeholder: "Senamiestis, centras, ...",
  },
  {
    label: "Telefonas",
    name: "phone",
    type: "text",
    placeholder: "+370...",
  },
  {
    label: "Paslaugos",
    name: "services",
    type: "multiSelect",
    options: serviceTypes
      ? serviceTypes.map((type: OptionItemType) => ({
          name: type.type,
          label: type.label,
        }))
      : [],
  },
];
