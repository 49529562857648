import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { navigationList } from "../../../navigationSettings";
import ContentHeader from "../ContentHeader";
import { UserType } from '../uiContainerTypes';
import "./ContentContainer.scss";

type ContentContainerProps = {
  user: UserType
};

const ContentContainer: React.FC<ContentContainerProps> = ({ user }) => {
  return (
    <div className='ContentContainer'>
      <ContentHeader user={user} />
      <div className='ContentContainer-mainPart'>
        <Switch>
          {navigationList.map(({ path, component }) => (
            <Route path={path} key={path} component={component} />
          ))}
          <Redirect to="/dashboard"/>
        </Switch>
      </div>
    </div>
  );
};

export default ContentContainer;
