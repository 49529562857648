import React from 'react';
import './NoteBox.scss';

type NoteBoxType = {
  error?: boolean;
}

export const NoteBox: React.FC<NoteBoxType> = ({ error, children }) => (
  <div className={`NoteBox ${error && 'NoteBox-error'}`}>{children}</div>
);
