import React from "react";
import './Overlay.scss';

type OverlayProps = {
  onClick: () => void;
};

export const Overlay: React.FC<OverlayProps> = ({ onClick }) => {
  const clickHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
    onClick && onClick();
  return <div className='Overlay' onClick={clickHandler} />;
};
