import { gql } from "@apollo/client";

export const HOTEL_FRAGMENT = gql`
  fragment HotelDetails on Hotel {
    _id
    name
    ownerId
    owner {
      email
    }
    town
    type
    neighbourhood
    address
    phone
    seasons
    stars
    numberOfRooms
    services
  }
`;

export const SETTINGS_FRAGMENT = gql`
  fragment SettingsFields on Settings {
    _id
    hotelTypes {
      type
      label
    }
    serviceTypes {
      type
      label
    }
    towns {
      type
      label
    }
  }
`;
export const GET_MY_HOTEL = gql`
  query MyHotel {
    myHotel {
      ...HotelDetails
    }
    settings {
      ...SettingsFields
    }
  }
  ${HOTEL_FRAGMENT}
  ${SETTINGS_FRAGMENT}
`;

export const GET_HOTEL = gql`
  query GetHotel($id: String!) {
    hotel(id: $id) {
      ...HotelDetails
    }
    settings {
      ...SettingsFields
    }
  }
  ${HOTEL_FRAGMENT}
  ${SETTINGS_FRAGMENT}
`;

export const GET_HOTEL_BY_ID = gql`
  query GetHotel($id: String!, $hotelId: String) {
    hotel(id: $id, hotelId: $hotelId) {
      ...HotelDetails
    }
    settings {
      ...SettingsFields
    }
  }
  ${HOTEL_FRAGMENT}
  ${SETTINGS_FRAGMENT}
`;

export const GET_HOTEL_META = gql`
  query GetHotel($hotelId: String!, $year: Int!, $id: String!) {
    hotel(id: $id, hotelId: $hotelId) {
      ...HotelDetails
    }
    hotelReportsMeta(hotelId: $hotelId, year: $year) {
      months
    }
  }
  ${HOTEL_FRAGMENT}
`;

export const UPDATE_HOTEL = gql`
  mutation UpdateHotel($hotel: inHotel!) {
    updateHotel(hotel: $hotel) {
      ...HotelDetails
    }
  }
  ${HOTEL_FRAGMENT}
`;

export const GET_HOTELS_COUNT = gql`
  query GetHotelsCount($filter: inDashboardFilter!) {
    getHotelsCount(filter: $filter) {
      filter {
        town
        hotelType
        serviceTypes
        stars
      }
      count
    }
  }
`;

export const GET_HOTELS_LIST = gql`
  query GetHotelsList($filter: inDashboardFilter!) {
    filteredHotels(filter: $filter) {
      _id
      name
      stars
      town
    }
  }
`;
