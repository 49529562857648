import {gql} from '@apollo/client';

const REPORT_FRAGMENT = gql`
    fragment MonthReportFragment on MonthReport {
        year
        month
        total {
            rooms
            revenue
        }
        roomsAvailable
        occupancy
        averageDailyRate
        revPAR
        hotelsFound
        hotelsReported
        hotelsValidCount
    }
`;

export const MONTHLY_REPORT = gql`
    query MonthlyReport($from: inFilterMonth!, $to: inFilterMonth!, $userId: String!) {
        hotelMonthReport(from: $from, to: $to, userId: $userId) {
            hasReported
            own {
                ...MonthReportFragment
            }
            byFilters {
                ...MonthReportFragment
            }
        }
    }
    ${REPORT_FRAGMENT}
`;

export const ADD_FILTER = gql`
    mutation AddDashboardFilter($user: String!, $dashboardFilter: inDashboardFilter!) {
        addDashboardFilter(user: $user, dashboardFilter: $dashboardFilter) {
            _id
            email
            dashboardFilters {
                town
                hotelType
                serviceTypes
                stars
            }
        }
    }
`;

export const REMOVE_FILTER = gql`
    mutation RemoveDashboardFilter($user: String!, $index: Int!) {
        removeDashboardFilter(user: $user, index: $index) {
            _id
            email
            dashboardFilters {
                town
                hotelType
                serviceTypes
                stars
            }
        }
    }
`;

export const GET_REPORT = gql`
    query ExcelReportQuery($year: Int!, $month: Int!, $userId: String!) {
        reportForExcel(userId: $userId, year: $year, month: $month) {
            own {
                currentMonth {
                    ...MonthReportFragment
                }
                currentYTD {
                    ...MonthReportFragment
                }
                lastYearMonth {
                    ...MonthReportFragment
                }
                lastYearYTD {
                    ...MonthReportFragment
                }
            }
            filters {
                currentMonth {
                    ...MonthReportFragment
                }
                currentYTD {
                    ...MonthReportFragment
                }
                lastYearMonth {
                    ...MonthReportFragment
                }
                lastYearYTD {
                    ...MonthReportFragment
                }
            }
        }
    }
    ${REPORT_FRAGMENT}
`;

export const RECALCULATE_ALL_REPORTS = gql`
    mutation UpdateAllMonthlyReports($year: Int!, $month: Int!) {
        updateAllMonthlyReports(year: $year, month: $month)
    }
`;