import ExcelJs from 'exceljs';
import { MONTHS } from 'common/ui-components';
import { getUri } from 'network-settings';
import apolloClient from '../../apolloClient';
import { DashboardFilterType } from "./Dashboards.types";
import { GET_REPORT } from './dashboard.queries';
import { getFilterDetails } from "./dashboard.utils";

const getTemplateWorkbook = async (): Promise<ExcelJs.Workbook> => {
  const backUri = getUri();
  const templateResponse = await fetch(`${backUri}/report_template.xlsx`);
  const templateBlob = await templateResponse.arrayBuffer();
  const workbook = new ExcelJs.Workbook();
  await workbook.xlsx.load(templateBlob);
  return workbook;
}

const downloadFile = (buffer: ArrayBuffer, fileName: string) => {
  const blob = new Blob([buffer]);
  const link = document.createElement('a');
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const replaceCellString = (
  cells: ExcelJs.Cell[],
  template: string,
  value: string
) => {
  cells.forEach((cell:ExcelJs.Cell) => {
    const titleValue = cell.value;
    if (titleValue) {
      cell.value = titleValue.toString().replace(template, value);
    }
  })
};

const setRowFill = (
  row: ExcelJs.Row,
  firstRow: ExcelJs.Row,
  ids: number[],
  isFirstLine: boolean,
  isEven: boolean
) => {
  ids.forEach((id) => {
    const cell = row.getCell(id);
    // Copy style
    cell.style = { ...firstRow.getCell(id).style };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: !isEven ? 'FFDFDFDF' : 'FFFFFFFF',
      }
    };
    if (isFirstLine) {
      cell.border = {
        top: {
          style:'thin', color: {argb:'FF969696'}
        }
      }
    }
  })
};

const getPercentChange = (current?: number, last?: number): number | string | null => {
  if (!current || !last) {
    return null;
  }
  const diff = current - last;
  return (diff / last) * 100;
}

const setPercentageChange = (cell: ExcelJs.Cell, current?: number, last?: number): void => {
  const change = getPercentChange(current, last);
  if (change) {
    cell.value = change;
  } else {
    cell.value = '--';
    cell.alignment = { horizontal: 'right' };
  }
}

const fillRow = (
  wb: ExcelJs.Worksheet,
  name: string,
  lineNumber: number,
  data: Record<string, any>,
  nameComment?: string,
) => {
  const firstRow = wb.getRow(8);
  const row = wb.getRow(lineNumber);
  // if (lineNumber % 2 === 0) {
  //   console.log(lineNumber);
    setRowFill(
      row,
      firstRow,
      [2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
      lineNumber === 8,
      lineNumber % 2 === 0
    );
  // }
  row.getCell(2).value = name;
  if (nameComment) {
    row.getCell(2).note = nameComment;
  }
  row.getCell(4).value = data?.currentMonth?.occupancy ? (data?.currentMonth?.occupancy * 100) : '';
  row.getCell(5).value = data?.lastYearMonth?.occupancy ? (data?.lastYearMonth?.occupancy * 100) : '';
  row.getCell(6).value = data?.currentMonth?.averageDailyRate;
  row.getCell(7).value = data?.lastYearMonth?.averageDailyRate;
  row.getCell(8).value = data?.currentMonth?.revPAR;
  row.getCell(9).value = data?.lastYearMonth?.revPAR;
  
  setPercentageChange(row.getCell(10), data?.currentMonth?.occupancy, data?.lastYearMonth?.occupancy);
  setPercentageChange(row.getCell(11), data?.currentMonth?.averageDailyRate, data?.lastYearMonth?.averageDailyRate);
  setPercentageChange(row.getCell(12), data?.currentMonth?.revPAR, data?.lastYearMonth?.revPAR);
  row.getCell(13).value = data?.currentMonth?.total?.revenue;
  row.getCell(14).value = data?.currentMonth?.roomsAvailable;
  row.getCell(15).value = data?.currentMonth?.total?.rooms;

  row.getCell(17).value = data?.currentYTD?.occupancy ? (data?.currentYTD?.occupancy * 100) : '';
  row.getCell(18).value = data?.lastYearYTD?.occupancy ? (data?.lastYearYTD?.occupancy * 100) : '';
  row.getCell(19).value = data?.currentYTD?.averageDailyRate;
  row.getCell(20).value = data?.lastYearYTD?.averageDailyRate;
  row.getCell(21).value = data?.currentYTD?.revPAR;
  row.getCell(22).value = data?.lastYearYTD?.revPAR;
  
  setPercentageChange(row.getCell(23), data?.currentYTD?.occupancy, data?.lastYearYTD?.occupancy);
  setPercentageChange(row.getCell(24), data?.currentYTD?.averageDailyRate, data?.lastYearYTD?.averageDailyRate);
  setPercentageChange(row.getCell(25), data?.currentYTD?.revPAR, data?.lastYearYTD?.revPAR);
  row.getCell(26).value = data?.currentYTD?.total?.revenue;
  row.getCell(27).value = data?.currentYTD?.roomsAvailable;
  row.getCell(28).value = data?.currentYTD?.total?.rooms;

};

const setHeaders = (row: ExcelJs.Row, cellIds: number[]) => {
  cellIds.forEach((cellId) => {
    const cell = row.getCell(cellId);
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'FF6E6259',
      }
    };
    cell.border = {
      bottom: {
        style:'thin', color: {argb:'FF969696'}
      }
    }
  })
};

export const excelExport = async (
  hotelName: string,
  year: number,
  month: number,
  hotelData: Record<string, any>[],
  filters: DashboardFilterType[],
  userId: string,
) => {
  const [workbook, queryResponse] = await Promise.all([
    getTemplateWorkbook(),
    apolloClient.query({
      query: GET_REPORT,
      fetchPolicy: 'network-only',
      variables: {
        year,
        month,
        userId,
      }
    })
  ]);

  const worksheet = workbook.worksheets[0];
  setHeaders(worksheet.getRow(5), [4, 17]);

  // Cells With thisMonthYear
  const thisMonthYearCells = [
    worksheet.getRow(2).getCell(2),
    worksheet.getRow(5).getCell(4),
    worksheet.getRow(5).getCell(17),
  ];
  replaceCellString(thisMonthYearCells, 'thisMonthYear', `${MONTHS[month]} ${year}`);

  // Cells With lastMonthYear
  const lastMonthYearCells = [
    worksheet.getRow(5).getCell(4),
    worksheet.getRow(5).getCell(17),
    worksheet.getRow(6).getCell(10),
    worksheet.getRow(6).getCell(23),
  ];
  replaceCellString(lastMonthYearCells, 'lastMonthYear', `${MONTHS[month]} ${year - 1}`);

  // Cells With thisYear
  const row7 = worksheet.getRow(7);
  const thisYearCells = [
    row7.getCell(4),
    row7.getCell(6),
    row7.getCell(8),
    row7.getCell(17),
    row7.getCell(19),
    row7.getCell(21),
  ];
  replaceCellString(thisYearCells, 'thisYear', year.toString());

  // Cells With lastYear
  const lastYearCells = [
    row7.getCell(5),
    row7.getCell(7),
    row7.getCell(9),
    row7.getCell(18),
    row7.getCell(20),
    row7.getCell(22),
  ];
  replaceCellString(lastYearCells, 'lastYear', (year - 1).toString());
  fillRow(worksheet, hotelName, 8, queryResponse.data.reportForExcel.own);
  if (queryResponse?.data?.reportForExcel?.filters) {
    queryResponse.data.reportForExcel.filters.forEach((item: any, index: number) => {
      console.log({index, item});
      fillRow(
        worksheet,
        `Filtras nr. ${index + 1}`,
        9 + index,
        item,
        getFilterDetails(filters[index]));
    });
  }
  const buffer = await workbook.xlsx.writeBuffer();

  downloadFile(buffer, `ataskaita_${year}-${month < 10 ? "0" : ""}${month + 1}.xlsx`);
}