export const WEEKDAYS_SHORT = ["Sk", "Pr", "An", "Tr", "Kt", "Pn", "Š"];

export const MONTHS = [
  "Sausis",
  "Vasaris",
  "Kovas",
  "Balandis",
  "Gegužė",
  "Birželis",
  "Liepa",
  "Rugpjūtis",
  "Rugsėjis",
  "Spalis",
  "Lapkritis",
  "Gruodis",
];

export const MONTHS_WHO = [
  "Sausio",
  "Vasario",
  "Kovo",
  "Balandžio",
  "Gegužės",
  "Birželio",
  "Liepos",
  "Rugpjūčio",
  "Rugsėjo",
  "Spalio",
  "Lapkričio",
  "Gruodžio",
];

export const WEEKDAYS_LONG = [
  "Sekmadienis",
  "Pirmadienis",
  "Antradienis",
  "Trečiadienis",
  "Ketvirtadienis",
  "Penktadienis",
  "Šeštadienis",
];

export const WEEKDAYS_LONG_MONDAY = [
  "Pirmadienis",
  "Antradienis",
  "Trečiadienis",
  "Ketvirtadienis",
  "Penktadienis",
  "Šeštadienis",
  "Sekmadienis",
];

export const FIRST_DAY_OF_WEEK = 1;
// Translate aria-labels
export const LABELS = {
  nextMonth: "sekantis mėnuo",
  previousMonth: "ankstesnis mėnuo",
};
