import React, { MouseEvent, useCallback } from "react";
import "./Checkbox.scss";

type CheckboxProps = {
  value: boolean;
  onToggle: () => void;
};

// https://codemyui.com/pure-css-neumorphic-design-toggle-switch/
export const Checkbox: React.FC<CheckboxProps> = ({ value, onToggle }) => {
  const changeHandle = useCallback(
    (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      onToggle();
    },
    [onToggle]
  );
  const fakeHadler = useCallback(() => {}, []);
  return (
    <div className={`Checkbox ${value && 'Checkbox-checked'}`} onClick={changeHandle}>
      <input className='Checkbox-state' type='checkbox' checked={value} onChange={fakeHadler} />
      <div className='Checkbox-indicator'></div>
    </div>
  );
};
