import React, { useCallback } from "react";
import { MultiSelect, SelectOptionType } from "ui-components";

export type InputProps = {
  key?: string;
  label?: string;
  name: string;
  value: any;
  placeholder?: string;
  options?: SelectOptionType[];
  disabled?: boolean;
  onChange: (name: string | number, value: any) => void;
};

export const InputMultiSelect: React.FC<InputProps> = ({
  label,
  name,
  value,
  options,
  disabled,
  onChange
}) => {
  const changeHandler = useCallback(
    (newValue: (string | number)[]) => onChange(name, newValue),
    [name, onChange]
  );

  return (
    <div className='FormInput mb-m'>
      {label ? <div className='FormInput-label mb-xs'>{label}</div> : null}
      <MultiSelect
        selected={value || []}
        onChange={changeHandler}
        options={options || []}
        disabled={disabled}
      />
    </div>
  );
};
