import React, { useCallback, useEffect, useState } from "react";
import { Button, SelectOptionType } from "ui-components";
import { InputText, InputProps } from "./InputText";
import { InputSelect } from "./InputSelect";
import { InputMultiSelect } from "./InputMultiSelect";

export type FormFieldType = {
  label: string;
  name: string;
  type: "text" | "number" | "custom" | "select" | "multiSelect";
  options?: SelectOptionType[];
  placeholder?: string;
  component?: (props: InputProps) => JSX.Element;
};

type FormType = {
  initialState: Record<string, any>;
  fields: FormFieldType[];
  className?: string;
  disabledSubmit?: boolean;
  onSubmit?: (data: Record<string, any>) => void;
  onUpdate?: (state: Record<string, any>) => void;
  hideSubmit?: boolean;
  additionalButtonName?: string;
  onAdditionalButton?: () => void;
};

export const Form: React.FC<FormType> = ({
  initialState,
  fields,
  className,
  disabledSubmit,
  onSubmit,
  onUpdate,
  hideSubmit,
  onAdditionalButton,
  additionalButtonName
}) => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setFormState(initialState);
  }, [initialState]);

  useEffect(() => {
    setDisabled(onSubmit ? false : true);
  }, [onSubmit]);

  useEffect(() => {
    onUpdate && onUpdate(formState);
  }, [formState, onUpdate]);

  const changeHandler = useCallback(
    (name: string | number, value: any, type?: string) => {
      setFormState({
        ...formState,
        [name]: type === "number" ? parseFloat(value) : value,
      });
    },
    [formState]
  );

  const submitHandler = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onSubmit && onSubmit(formState);
    },
    [formState, onSubmit]
  );

  return (
    <div className={className}>
      {fields.map((field) => {
        if (field.component) {
          return field.component({
            key: field.name,
            name: field.name,
            value: formState[field.name],
            placeholder: field.placeholder,
            onChange: changeHandler,
            disabled: disabled,
            label: field.label,
          });
        } else if (field.type === "text") {
          return (
            <InputText
              key={field.name}
              {...field}
              value={formState[field.name]}
              disabled={disabled}
              onChange={changeHandler}
            />
          );
        } else if (field.type === "number") {
          return (
            <InputText
              key={field.name}
              {...field}
              value={formState[field.name]}
              disabled={disabled}
              onChange={changeHandler}
            />
          );
        } else if (field.type === "select") {
          return (
            <InputSelect
              key={field.name}
              name={field.name}
              label={field.label}
              options={field.options}
              value={formState[field.name]}
              disabled={disabled}
              onChange={changeHandler}
            />
          );
        } else if (field.type === "multiSelect") {
          return (
            <InputMultiSelect
              key={field.name}
              name={field.name}
              label={field.label}
              options={field.options}
              value={formState[field.name]}
              disabled={disabled}
              onChange={changeHandler}
            />
          );
        }
        return null;
      })}
      {onSubmit && !hideSubmit ? (
        <div className='mt-l'>
          <Button color='primary' disabled={disabledSubmit} onClick={submitHandler}>
            Išsaugoti
          </Button>
          {onAdditionalButton ? <Button color="secondary" onClick={onAdditionalButton} className='ml-s'>
            {additionalButtonName}
          </Button> : null}
        </div>
      ) : null}
    </div>
  );
};
