import { gql } from "@apollo/client";

const SALE_ITEM_FRAGMENT = gql`
  fragment SaleItemFragment on SaleItem {
    rooms
    revenue
  }
`;

const SALE_DETAILS_FRAGMENT = gql`
  fragment SoldDetailsFragment on ReportSoldDetails {
    transient {
      ...SaleItemFragment
    }
    group {
      ...SaleItemFragment
    }
    contract {
      ...SaleItemFragment
    }
    total {
      ...SaleItemFragment
    }
  }
  ${SALE_ITEM_FRAGMENT}
`;

export const WEEK_REPORTS_QUERY = gql`
  query WeeklyReports(
    $from: inFilterWeek!
    $to: inFilterWeek!
    $hotelIds: [String]!
  ) {
    weeklyReports(from: $from, to: $to, hotelIds: $hotelIds) {
      _id
      hotelId
      year
      weekNumber
      roomsAvailable
      sold {
        ...SoldDetailsFragment
      }
    }
  }
  ${SALE_DETAILS_FRAGMENT}
`;

export const DAY_REPORTS_QUERY = gql`
  query DailyReports(
    $from: inFilterDay!
    $to: inFilterDay!
    $year: Int!
    $month: Int!
    $hotelId: String!
    $hotelIds: [String]!
  ) {
    dailyReports(from: $from, to: $to, hotelIds: $hotelIds) {
      _id
      hotelId
      year
      month
      day
      roomsAvailable
      sold {
        ...SoldDetailsFragment
      }
    }
    hotelSingleMonthReport(year: $year, month: $month, hotelId: $hotelId) {
      roomsAvailable
      occupancy
      averageDailyRate
      revPAR
    }
  }
  ${SALE_DETAILS_FRAGMENT}
`;

export const UPDATE_WEEK = gql`
  mutation UpdateWeek($report: inWeeklyReport!) {
    updateWeeklyReport(report: $report) {
      _id
      hotelId
      year
      weekNumber
      roomsAvailable
      sold {
        total {
          rooms
          revenue
        }
      }
    }
  }
`;

export const UPDATE_DAY = gql`
  mutation UpdateDay($report: inDailyReport!) {
    updateDailyReport(report: $report) {
      _id
      hotelId
      year
      month
      day
      roomsAvailable
      sold {
        total {
          rooms
          revenue
        }
      }
    }
  }
`;

export const UPDATE_ROOMS = gql`
  mutation UupdateRoomsForMonth(
    $hotelId: String!
    $year: Int!
    $month: Int!
    $roomsAvailable: Int!
  ) {
    updateRoomsForMonth(
      hotelId: $hotelId,
      year: $year,
      month: $month
      roomsAvailable: $roomsAvailable
    ) {
      _id
      hotelId
      year
      month
      day
      roomsAvailable
      sold {
        total {
          rooms
          revenue
        }
      }
    }
  }
`;
