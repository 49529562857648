import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { DayModifiers, Modifiers } from "react-day-picker";
import { Card, Calendar } from "ui-components";
import {
  getMonthStart,
  getMonthEnd,
  getTimestamp,
  getDateFromTimestamp,
  modifiersStyles,
} from "common/utils";
import EventsDay from "./EventsDay";
import { EVENTS_QUERY } from "./events.gql";
import "./Events.scss";

type QueryDateType = {
  startDate: number;
  endDate: number;
};

const Events = () => {
  const todayDate = new Date();
  const today = getTimestamp(todayDate);
  const [modifiers, setModifiers] = useState<Partial<Modifiers>>({
    selected: [],
  });
  const [eventsMapped, setEventsMapped] = useState<
    Map<number, Record<string, any>[]>
  >(new Map());
  const [queryDates, setQueryDates] = useState<QueryDateType>({
    startDate: getMonthStart(todayDate),
    endDate: getMonthEnd(todayDate),
  });
  const [calendarDate, setCalendarDate] = useState(today);

  const { data: eventsData, refetch: eventsRefetch } = useQuery(EVENTS_QUERY, {
    // skip: !mySelfData?.user?.hotel?._id,
    variables: {
      startDate: queryDates.startDate,
      endDate: queryDates.endDate,
    },
  });

  useEffect(() => {
    if (!eventsData?.events) {
      return setEventsMapped(new Map());
    }
    const { mapped, selected } = eventsData.events.reduce(
      (
        acc: { mapped: Map<number, Record<string, any>[]>; selected: Date[] },
        item: any
      ) => {
        const currentEvents = acc.mapped.get(item.date);
        acc.mapped.set(
          item.date,
          currentEvents ? [...currentEvents, item] : [item]
        );
        if (!currentEvents) {
          acc.selected = [...acc.selected, getDateFromTimestamp(item.date)];
        }
        return acc;
      },
      { mapped: new Map(), selected: [] }
    );
    setEventsMapped(mapped);
    setModifiers({ selected });
  }, [eventsData]);

  const dayClickHandler = (day: Date, modifier: DayModifiers) => {
    setCalendarDate(getTimestamp(day));
    setQueryDates({
      startDate: getMonthStart(day),
      endDate: getMonthEnd(day),
    });
  };

  return (
    <div className='ph-l DataRegistration'>
      <div className='mr-m'>
        <Card>
          <Calendar
            showWeekNumbers
            month={getDateFromTimestamp(calendarDate)}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            onDayClick={dayClickHandler}
          />
        </Card>
      </div>
      <EventsDay
        calendarDate={getDateFromTimestamp(calendarDate)}
        timestamp={calendarDate}
        events={eventsMapped.get(calendarDate)}
        eventsRefetch={eventsRefetch}
        eventTypes={eventsData?.settings?.eventTypes}
      />
    </div>
  );
};

export default Events;
