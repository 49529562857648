import numeral from 'numeral';

export const formatDouble = (num?: number) => num ? numeral(num).format("0.00") : '--';

export const formatPercentage = (num?: number | null) => num ? numeral(num).format("0.[0]") : '--';

export const getDiffPercentage = (prev: number | null, current?: number) => {
  if (!prev || !current) {
    return null;
  }
  return ((current - prev) / prev) * 100;
}
