import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  useQuery,
  useLazyQuery,
  useMutation,
  ApolloError,
} from "@apollo/client";
import { Overlay, MultiSelect, NoteBox } from "ui-components";
import { Form } from "ui-form";
import { getFields } from "./dashboard.formFields";
import {
  GET_MY_HOTEL,
  GET_HOTELS_COUNT,
  GET_HOTELS_LIST,
} from "../Hotel/hotel.queries";
import { ADD_FILTER } from "./dashboard.queries";

type FilterFields = {
  hotelType: string[];
  stars: number[];
  town: string[];
  serviceTypes: string[];
};

type DashboardAddFilterProps = {
  onClose: (refetch: boolean) => void;
  filterValues?: FilterFields;
  filterIndex?: number;
};

const initialState = {};

const DashboardAddFilter: React.FC<DashboardAddFilterProps> = ({ onClose }) => {
  const { data } = useQuery(GET_MY_HOTEL);
  const [selectedHotels, setSelectedHotels] = useState<(string | number)[]>([]);
  const [getHotelsCountQuery, { data: hotelsResult }] = useLazyQuery(
    GET_HOTELS_COUNT
  );
  const [getHotelsListQuery, { data: hotelsList }] = useLazyQuery(
    GET_HOTELS_LIST
  );
  const [addFilterQuery] = useMutation(ADD_FILTER, {
    onCompleted: () => {
      onClose(true);
      alert("Pakeitimai išsaugoti!");
    },
    onError: (error: ApolloError) => console.error(error),
  });

  useEffect(() => {
    if (hotelsResult) {
      const { filter } = hotelsResult.getHotelsCount;
      const { __typename, ...dashboardFilter } = filter;
      addFilterQuery({
        variables: {
          user: data.myHotel?.ownerId,
          dashboardFilter: { ...dashboardFilter, selectedHotels },
        },
      });
    }
  }, [hotelsResult, addFilterQuery, data, selectedHotels]);

  const overlayClickHandler = useCallback(() => onClose(false), [onClose]);

  const handleChange = useCallback(
    (filter) => {
      getHotelsListQuery({ variables: { filter } });
    },
    [getHotelsListQuery]
  );

  const handleSubmit = useCallback(
    (filter: Record<string, any>) => {
      getHotelsCountQuery({ variables: { filter } });
    },
    [getHotelsCountQuery]
  );

  const fields = useMemo(
    () =>
      getFields(
        data?.settings?.hotelTypes || [],
        data?.settings?.serviceTypes || [],
        data?.settings?.towns || []
      ),
    [data]
  );

  const hotelsOptions = useMemo(() => {
    return !hotelsList?.filteredHotels?.length
      ? []
      : hotelsList.filteredHotels
        .filter((hotel: any) => hotel.name !== '')
        .map((hotel: any) => ({
          name: hotel._id,
          label: hotel.name,
        }));
  }, [hotelsList]);

  const isDisabled = useMemo(() => {
    return selectedHotels.length > 0 && selectedHotels.length < 5;
  }, [selectedHotels])

  const hotelSelectHandler = useCallback((selected: (string | number)[]) => {
    setSelectedHotels(selected);
  }, []);
  
  return (
    <>
      <Overlay onClick={overlayClickHandler} />
      <div className='DashboardAddFilter'>
        <div className='DashboardAddFilter-header'>Pridėti filtrą</div>
        <div className='flexRow'>
          <div className='DashboardAddFilter-form'>
            <Form
              initialState={initialState}
              fields={fields}
              onSubmit={handleSubmit}
              onUpdate={handleChange}
              disabledSubmit={isDisabled}
            />
          </div>
          <div className='ml-m DashboardAddFilter-hotelsList'>
            <>
              <div className='mb-xs'>Viešbučių sąrašas</div>
              {hotelsList?.filteredHotels?.length && hotelsList?.filteredHotels?.length >=5 ? (
                <>
                  <MultiSelect
                    selected={selectedHotels}
                    options={hotelsOptions}
                    onChange={hotelSelectHandler}
                  />
                  {isDisabled ? (
                    <div className='mt-m'>
                      <NoteBox>
                        Norint išsaugoti filtrą su pasirinktais viešbučiais, mažiausiai 5 turi būti pasirinkti
                      </NoteBox>
                    </div>
                  ) : null}
                </>
              ) : (
                <NoteBox>
                  Sąrašas bus rodomas tik pasirinkus filtavimo parametrus ir bus rasti 5 ir daugiau objektai
                </NoteBox>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAddFilter;
