import React, { useState, useRef, useEffect, useCallback } from "react";
import { useMutation, ApolloError } from "@apollo/client";
import { Card } from "ui-components";
import { InputText } from "ui-form";
import { UPDATE_DAY } from './MonthlyReport.queries';

type MonthlyReportDayProps = {
  year: number;
  month: number;
  day: number;
  hotelId?: string;
  data?: any;
  roomsAvailable?: number;
  refetchQuery: () => void;
};

const MonthlyReportDay: React.FC<MonthlyReportDayProps> = ({
  year,
  month,
  day,
  hotelId,
  data,
  roomsAvailable,
  refetchQuery
}) => {
  const [values, setValues] = useState<Record<string, string>>({});
  const [isFocused, setFocused] = useState(true);
  const [isUpdated, setUpdated] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const [updateDayMutation] = useMutation(UPDATE_DAY, {
    onCompleted: () => {
      refetchQuery();
      setUpdated(false);
      // alert("Pakeitimai išsaugoti!");
    },
    onError: (error: ApolloError) => console.error(error),
  });

  const saveChanges = useCallback((updated?: any) => {
    const saveValues = updated || values;
    const sold = {
      total: {
        rooms: parseInt(saveValues?.rooms),
        revenue: parseFloat(saveValues?.revenue),
      }
    }

    const report = {
      _id: saveValues._id,
      hotelId,
      year,
      month,
      day,
      roomsAvailable,
      sold,
    };
    updateDayMutation({
      variables: {
        report,
      },
    });
  },[hotelId, values, year, month, day, roomsAvailable, updateDayMutation]);

  const changeHandler = (name: string, value: string) => {
    setUpdated(true);
    if (name) {
      setValues((current) => ({ ...current, [name]: value }));
    }
  };

  const focusHandler = (name: string) => {
    setFocused(true);
  };

  const blurHandler = (name: string) => {
    setFocused(false);
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (!isFocused && isUpdated) {
      timerRef.current = setTimeout(() => {
        saveChanges();
      }, 500);
    }
    return () => timerRef.current && clearTimeout(timerRef.current);
  }, [isFocused, isUpdated, saveChanges]);

  useEffect(() => {
    const updatedValues = {
      roomsAvailable: data?.roomsAvailable,
      rooms: data?.sold?.total?.rooms,
      revenue: data?.sold?.total?.revenue
    }
    setValues(updatedValues);
  }, [data]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (!isFocused && isUpdated) {
      timerRef.current = setTimeout(() => {
        saveChanges();
      }, 500);
    }
    return () => timerRef.current && clearTimeout(timerRef.current);
  }, [isFocused, isUpdated, saveChanges]);

  return (
    <Card className='mr-s mb-m MonthlyReport-calendarDay'>
      <div className='highlighted mb-xs'>{day} d.</div>
      {/* <InputText
        label='Kambariai'
        value={values.roomsAvailable}
        type='number'
        name='roomsAvailable'
        onChange={changeHandler}
        onFocus={focusHandler}
        onBlur={blurHandler}
      /> */}
      <InputText
        label='Parduoti (i)'
        tooltip='Parduoti kambariai'
        value={values.rooms}
        type='number'
        name='rooms'
        onChange={changeHandler}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
      <InputText
        label='Pajamos (i)'
        tooltip='Pajamos pateikiamos be PVM ir pusryčių (nuogos)'
        value={values.revenue}
        type='number'
        name='revenue'
        onChange={changeHandler}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
    </Card>
  );
};

export default MonthlyReportDay;
