import React, { useCallback } from "react";
import { Input, Tooltip } from "ui-components";

export type InputProps = {
  key?: string;
  label?: string;
  type?: string;
  name: string;
  value: any;
  disabled?: boolean;
  placeholder?: string;
  tooltip?: string;
  noMargins?: boolean;
  onChange: (name: string, value: any, type?: string) => void;
  onFocus?: (name: string) => void;
  onBlur?: (name: string) => void;
  onSubmit?: () => void;
};

export const InputText: React.FC<InputProps> = ({
  label,
  name,
  type,
  value,
  placeholder,
  tooltip,
  noMargins,
  disabled,
  onChange,
  onFocus,
  onBlur,
  onSubmit
}) => {
  const changeHandler = useCallback(
    (newValue: string) => onChange(name, newValue, type),
    [name, onChange, type]
  );

  return (
    <div className={`FormInput ${noMargins ? '' : 'mb-m'}`}>
      {label ?
        <div className='FormInput-label mb-xs'>
          {tooltip ? (
            <Tooltip text={tooltip}>
              {label}
            </Tooltip>
          ) : label}
        </div> :
        null
      }
      <Input
        value={value || (value === 0 ? 0 : '')}
        type={type || 'text'}
        onChange={changeHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        onEnterKey={onSubmit}
        disabled={disabled}
      />
    </div>
  );
};
