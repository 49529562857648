import React, { useCallback } from 'react';
import './Button.scss';

export type ButtonProps = {
  active?: boolean;
  disabled?: boolean;
  color?: string;
  size?: string;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button: React.FC<ButtonProps> = ({
  active,
  disabled,
  color,
  size,
  className,
  onClick,
  ...attributes
}) => {
  const clickHandler = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (onClick) {
      onClick(e);
    }
  }, [disabled, onClick]);

  const colorClass = color ? `Button-${color}` : '';
  return <button type='button' {...attributes} onClick={clickHandler} className={`Button ${className} ${colorClass}`} />
}
