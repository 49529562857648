import React from 'react';

type HotelStarsType = {
    stars: number;
}

const starsList = ["★", "★★", "★★★", "★★★★", "★★★★★"];
const HotelStars: React.FC<HotelStarsType> = ({ stars }) => (
    <div className='HotelsItem-stars mh-xs'>
        {starsList[stars - 1]}
    </div>
);

export default HotelStars;
