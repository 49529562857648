import React, { useState, useCallback, useRef } from "react";
import { Overlay } from "../Overlay/Overlay";
import "./Select.scss";

export type SelectOptionType = {
  name: string | number;
  label: string | number;
};

type SelectProps = {
  selected: string | number;
  options: SelectOptionType[];
  disabled?: boolean;
  plain?: boolean;
  onChange: (value: string | number) => void;
};

export const Select: React.FC<SelectProps> = ({
  selected,
  options,
  disabled,
  plain,
  onChange,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [dimentions, setDimentions] = useState({ x: 0, y: 0, width: 0 });
  const selectRef = useRef<HTMLDivElement>(null);

  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      if (selectRef && selectRef.current) {
        const elementDim = selectRef.current.getBoundingClientRect();
        if (elementDim) {
          const { x, y, width } = elementDim;
          setDimentions({ x, y, width });
        }
      }
      setOpen(true);
    },
    [disabled]
  );

  const overlayClickHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const selectOptions = (value: string | number) => {
    onChange(value);
    setOpen(false);
  };

  const selectedOption = options.find(
    (option: SelectOptionType) => option.name === selected
  );
  return (
    <>
      <div className={`Select ${!plain ? 'Select--bordered' : ''}`} onClick={clickHandler} ref={selectRef}>
        <div className='Select-text'>
          {selectedOption?.label || "Pasirinkite"}
        </div>
        <div className='Select-icon ml-xs'>▽</div>
      </div>
      {isOpen ? (
        <>
          <Overlay onClick={overlayClickHandler} />
          <div
            className='Select-options'
            style={{
              top: dimentions.y + 45,
              left: dimentions.x,
              width: dimentions.width,
            }}
          >
            {options.map((option: SelectOptionType) => (
              <div
                key={option.name}
                className={`Select-optionsItem ${
                  option.name === selected ? "Select-optionsItem--selected" : ""
                }`}
                onClick={() => selectOptions(option.name)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};
