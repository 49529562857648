import { DashboardFilterType } from "./Dashboards.types";

export const starsList = ["", "★", "★★", "★★★", "★★★★", "★★★★★"];
export const reportFields = ['occupancy', 'averageDailyRate', 'revPAR'];

export const getFilterDetails = (filter: DashboardFilterType) => {
  if (filter.selectedHotelNames) {
    return filter.selectedHotelNames.join(('; '));
  }
  const towns =
    filter.town && filter.town.length > 0 ? `${filter.town.join(", ")}; ` : "";
  const hotelType =
    filter.hotelType && filter.hotelType.length > 0
      ? `${filter.hotelType.join(", ")}; `
      : "";
  const stars =
    filter.stars && filter.stars.length > 0
      ? `${filter.stars
          .map((starCount: number) => starsList[starCount])
          .join(", ")}; `
      : "";
  const serviceTypes =
    filter.serviceTypes && filter.serviceTypes.length > 0
      ? `${filter.serviceTypes.join(", ")}; `
      : "";
  return [towns, hotelType, stars, serviceTypes].join("");
};

export const getFilterName = (filter: DashboardFilterType, index?: number) => {
  const counter = index !== undefined ? `Filtras nr. ${index + 1}; ` : '';
  return `${counter}${getFilterDetails(filter)}`;
}