import React from "react";
import { Link } from "react-router-dom";

type NavBarItemProps = {
  label: string;
  path: string;
  selected: boolean;
};

const NavBarItem: React.FC<NavBarItemProps> = ({ label, path, selected }) => {
  return (
    <div className={`NavBarItem pl-m ${
      selected ? "NavBarItem-selected" : ""
    }`}>
      <div className='NavBarItem-sides'>
        <div className='NavBarItem-before' />
      </div>
      <div className='NavBarItem-container pv-s ph-m'>
        <Link
          to={path}
          className='NavBarItem-link'
        >
          {label}
        </Link>
      </div>
      <div className='NavBarItem-sides'>
        <div className='NavBarItem-after' />
      </div>
    </div>
  );
};

export default React.memo(NavBarItem);
