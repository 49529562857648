import { gql } from '@apollo/client';

export const EVENT_FRAGMENT = gql`
  fragment EventDetails on Event {
    _id
    name
    date
    length
    town
    ownerId
    eventTypeId
    peopleCount
  }
`;

export const EVENTS_QUERY = gql`
  query Events($startDate: Int, $endDate: Int) {
    events(startDate: $startDate, endDate: $endDate) {
      ...EventDetails
    }
    settings {
      _id
      eventTypes {
        type
        label
      }
    }
  }
  ${EVENT_FRAGMENT}
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($event: inEvent!) {
    updateEvent(event: $event) {
      ...EventDetails
    }
  }
  ${EVENT_FRAGMENT}
`;
