import React from "react";
import './Avatar.scss';

type AvatarProps = {
  image?: any;
  text?: string;
  size?: "small" | "medium" | "large";
  withShadow?: boolean;
  onClick?: () => void;
};

const getAvatarText = (name?: string) => {
  if (!name) {
    return '';
  }
  const splittedText = name.split(' ');
  const result = (splittedText.length >= 2) ? `${splittedText[0][0]}${splittedText[1][0]}` : `${name[0]}${name[1]}`;
  return result.toUpperCase();
}

export const Avatar: React.FC<AvatarProps> = ({
  image,
  text,
  size,
  withShadow,
  onClick,
}) => {
  // TODO: implement image
  return (
    <div
      className={`Avatar Avatar-${size} ${onClick ? "Avatart-clickable" : ""} ${
        withShadow ? "Avatar-withShadow" : ""
      } ml-s`}
      onClick={onClick}
    >
      {getAvatarText(text)}
    </div>
  );
};
