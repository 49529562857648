import React, { useState, useCallback } from "react";
import { SetterOrUpdater, useSetRecoilState } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import * as H from "history";
import { tokenState } from "state/session";
import { Button, Card, Input } from "ui-components";
import { RouteLocation } from 'common/types';
import {
  login,
  verifyUser,
  validateEmail,
  resetPassword,
  confirmResetPassword,
} from "./loginUtils";
import "./Login.scss";

export const Login = () => {
  const { search } = useLocation<RouteLocation>();
  const prefillUser =
    search.includes("user") && search.length > 7
      ? search.replace("?user=", "")
      : "";
  const [user, setUser] = useState(prefillUser);
  const [password, setPassword] = useState("");
  const [badLogin, setBadLogin] = useState(false);
  const [isRequestResetMode, setIsRequestReset] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const setToken = useSetRecoilState(tokenState);
  const isVerify = search.includes("token") && search.length > 9;
  const isResetPassword = search.includes("resetPwd") && search.length > 11;
  const history = useHistory();

  const performLogin = useCallback(
    async (
      user: string,
      password: string,
      setToken: SetterOrUpdater<string>,
      history: H.History<H.LocationState>,
      isVerify: boolean,
      search: string
    ) => {
      if (isVerify) {
        const token = search.replace("?token=", "");
        const email = await verifyUser(token, password, history.replace);
        if (email) {
          setUser(email);
        }
      } else if (isResetPassword) {
        const token = search.replace("?resetPwd=", "");
        const email = await confirmResetPassword(
          token,
          password,
          history.replace
        );
        if (email) {
          setUser(email);
        }
      } else {
        const loginResponse = await login(
          user,
          password,
          setToken,
          history.push
        );
        console.log({ loginResponse });
        setBadLogin(!loginResponse);
        if (!loginResponse) {
          setPassword("");
        }
      }
    },
    [isResetPassword]
  );

  const loginHandler = useCallback(
    async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      performLogin(user, password, setToken, history, isVerify, search);
    },
    [user, password, setToken, history, isVerify, search, performLogin]
  );

  const passwordEnterKeyHandle = useCallback(
    () => performLogin(user, password, setToken, history, isVerify, search),
    [user, password, setToken, history, isVerify, search, performLogin]
  );

  const userChangeHandler = useCallback((value: string) => setUser(value), []);
  const passwordChangeHandler = useCallback(
    (value: string) => setPassword(value),
    []
  );

  const resetPasswordHandler = useCallback(() => {
    if (resetSent) {
      return;
    }
    if (isRequestResetMode) {
      if (!user) {
        return alert("Įveskite el. paštą!");
      } else if (!validateEmail(user)) {
        return alert("Nurodytas netinkamas el. paštas");
      }
      resetPassword(user);
      setResetSent(true);
    } else {
      setIsRequestReset(true);
    }
  }, [user, isRequestResetMode, resetSent]);

  const stopResetHandler = useCallback(() => {
    setIsRequestReset(false);
  }, [])

  const getLoginText = (isVerify: boolean, isResetPassword: boolean) =>
    isVerify
      ? "Jums sukurta paskyra. Nustatykit slaptažodį"
      : isResetPassword
      ? "Nustatykite naują slaptažodį"
      : "";

  return (
    <div className='Login-screen'>
      <Card>
        {isVerify || isResetPassword ? (
          <div className='mb-m'>{getLoginText(isVerify, isResetPassword)}</div>
        ) : (
          <div className='mb-m'>
            <div className='mb-xs'>
              {isRequestResetMode
                ? "Įveskite norimą atkurti el. paštą"
                : "El. paštas"}
            </div>
            <Input
              value={user}
              type='email'
              onChange={userChangeHandler}
              placeholder='info@hotel-name.lt'
            />
          </div>
        )}
        {!isRequestResetMode ? (
          <div className='mb-l Login-input'>
            <div className='mb-xs'>Slaptažodis</div>
            <Input
              value={password}
              type='password'
              onChange={passwordChangeHandler}
              placeholder='******'
              onEnterKey={passwordEnterKeyHandle}
            />
            {!isVerify && !isResetPassword ? (
              <div className='Login-forgotPassword'>
                <div
                  className='Login=forgotPasswordText'
                  onClick={resetPasswordHandler}
                >
                  Pamiršau slaptažodį
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {badLogin ? (
          <div className='Login-badLogin mb-m'>
            Neteisingi prisijungimo duomenys !
          </div>
        ) : null}
        <div className='Login-submitContainert'>
          <Button
            onClick={isRequestResetMode ? resetPasswordHandler : loginHandler}
            color='primary'
          >
            {isRequestResetMode
              ? "Atstatyti"
              : isVerify || isResetPassword
              ? "Nustatyti"
              : "Prisijungti"}
          </Button>
          {isRequestResetMode ? (
            <Button className='ml-m' onClick={stopResetHandler}>
              Atšaukti
            </Button>
          ) : null}
        </div>
      </Card>
      {resetSent ? (
        <div className='Login-resetSent mt-l p-m'>
          Slaptažodžio atkūrimo el. laiškas išsiųstas nurodytu el. pašto adresu,
          tačiau gali praeiti kelios minutės, kol atsiras gautųjų laiškų
          aplanke. Prašome palaukti bent 10 minučių prieš bandydami atkurti
          slaptažodį dar kartą.
        </div>
      ) : null}
    </div>
  );
};
