import React from "react";
import logo from './lvra-logo.png';
import { UserType } from '../uiContainerTypes';
import HeaderControls from './HeaderControls';
import './ContentHeader.scss';

type ContainerHeaderType ={
  user: UserType
};

const ContentHeader: React.FC<ContainerHeaderType> = ({ user }) => {
  return (
    <div className='ContentHeader ph-l mv-l'>
      <div className='ContentHeader-logoContainer'>
        <img src={logo} className='ContentHeader-logo' alt='logo' />
      </div>
      <div className='ContentHeader-middle' />
      <HeaderControls user={user} />
    </div>
  );
};

export default ContentHeader;
