import React, { useState, useRef, useEffect, useCallback } from "react";
import { gql, useMutation, ApolloError } from "@apollo/client";
import { useRecoilState } from "recoil";
import { clipboardState } from "state/registrationData";
import RecordInputs from "./RecordInputs";
import DayCardHeader from "./DayCardHeader";
import { mappedColumns } from "./registration.const";

const UPDATE_DAY = gql`
  mutation UpdateDay($report: inDailyReport!) {
    updateDailyReport(report: $report) {
      _id
      hotelId
      year
      month
      day
      roomsAvailable
      sold {
        total {
          rooms
          revenue
        }
      }
    }
  }
`;

type DayCardProps = {
  year: number;
  month: number;
  day: number;
  hotelId?: string;
  dayData?: any;
  refetchQuery: () => void;
};

const DayCard: React.FC<DayCardProps> = ({
  year,
  month,
  day,
  hotelId,
  dayData,
  refetchQuery,
}) => {
  const [values, setValues] = useState<Record<string, string>>({
    roomsAvailable: "334",
  });
  const [isFocused, setFocused] = useState(true);
  const [isUpdated, setUpdated] = useState(false);
  const [clipboard, setClipboard] = useRecoilState(clipboardState);

  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [updateDayMutation] = useMutation(UPDATE_DAY, {
    onCompleted: () => {
      refetchQuery();
      setUpdated(false);
      // alert("Pakeitimai išsaugoti!");
    },
    onError: (error: ApolloError) => console.error(error),
  });

  const saveChanges = useCallback((updated?: any) => {
    const saveValues = updated || values;
    const sold = mappedColumns.reduce((acc: Record<string, any>, row: any) => {
      return {
        ...acc,
        [row.name]: {
          rooms: saveValues[row.rooms] && parseFloat(saveValues[row.rooms]),
          revenue: saveValues[row.revenue] && parseFloat(saveValues[row.revenue]),
        },
      };
    }, {});
    const report = {
      _id: saveValues._id,
      hotelId,
      year,
      month,
      day,
      roomsAvailable: parseInt(saveValues.roomsAvailable),
      sold,
    };
    updateDayMutation({
      variables: {
        report,
      },
    });
  }, [hotelId, values, year, month, day, updateDayMutation]);

  const changeHandler = (value: string, name?: string) => {
    setUpdated(true);
    if (name) {
      setValues((current) => ({ ...current, [name]: value }));
    }
  };

  const focusHandler = (name: string) => {
    setFocused(true);
  };

  const blurHandler = (name: string) => {
    setFocused(false);
  };

  const copyHandler = useCallback(() => {
    const { _id, ...valuesToCopy } = values;
    setClipboard(valuesToCopy);
  }, [setClipboard, values])

  const pasteHandler = useCallback(() => {
    const updatedValues = { ...values, ...clipboard }
    setValues(updatedValues);
    saveChanges(updatedValues);
  }, [setValues, clipboard, values, saveChanges]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (!isFocused && isUpdated) {
      timerRef.current = setTimeout(() => {
        saveChanges();
      }, 500);
    }
    return () => timerRef.current && clearTimeout(timerRef.current);
  }, [isFocused, isUpdated, saveChanges]);

  useEffect(() => {
    // if (!weekData) {
    //   return;
    // }
    const updatedValues = mappedColumns.reduce(
      (acc, record) => {
        return {
          ...acc,
          [record.rooms]: dayData?.sold?.[record.name]?.rooms,
          [record.revenue]: dayData?.sold?.[record.name]?.revenue,
        };
      },
      { roomsAvailable: dayData?.roomsAvailable, _id: dayData?._id }
    );
    setValues(updatedValues);
  }, [dayData]);

  return (
    <div className='flexColumn'>
      <DayCardHeader
        year={year}
        month={month}
        day={day}
        copyEnabled={!!values?._id}
        onCopy={copyHandler}
        pasteEnabled={!!clipboard}
        onPaste={pasteHandler}
      />
      <div className='flexRow'>
        <RecordInputs
          onChange={changeHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
          values={values}
        />
      </div>
    </div>
  );
};

export default DayCard;
