import React from "react";
import { useQuery } from '@apollo/client';
import GET_MYSELF from 'common/queries/mySelf.query';
import NavBar from "../NavBar";
import ContentContainer from "../ContentContainer";
import "./AppContainer.scss";

const AppContainer = () => {
  const { data } = useQuery(GET_MYSELF);
  return (
    <div className='AppContainer'>
      <NavBar user={data && data.user} />
      <ContentContainer user={data && data.user} />
    </div>
  );
};

export default AppContainer;
