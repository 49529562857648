import React, { useCallback } from 'react';
import { useMutation, ApolloError } from "@apollo/client";
import { Card } from 'ui-components';
import { Form, FormFieldType } from "ui-form";
import { UPDATE_EVENT } from './events.gql';

type EventFormItem = {
  _id?: string;
  name?: string;
}

type EventFormProps = {
  item: EventFormItem;
  calendarDate: number;
  onSubmit: (item: EventFormItem) => void;
}

const eventFields: FormFieldType[] = [
  {
    label: "Pavadinimas",
    name: "name",
    type: "text",
    placeholder: "Renginio pavadinimas",
  },
  {
    label: "Trukmė",
    name: "length",
    type: "number",
    placeholder: "Renginio trukmė",
  },
  {
    label: "Miestas",
    name: "town",
    type: "text",
    placeholder: "Renginio miestas",
  },
  {
    label: "Žmonių skaičius",
    name: "peopleCount",
    type: "number",
    placeholder: "Planuojamas žmonių kiekis",
  },
]

const EventForm: React.FC<EventFormProps> = ({ item, calendarDate, onSubmit }) => {
  const [updateEventQuery] = useMutation(UPDATE_EVENT, {
    onCompleted: (data) => {
      alert("Pakeitimai išsaugoti!");
      onSubmit(data);
    },
    onError: (error: ApolloError) => console.error(error),
  });
  const handleSubmit = useCallback(
    (data: Record<string, any>) => {
      const { __typename, ...event } = data;
      const date = event.date || calendarDate;
      updateEventQuery({
        variables: {
          event: {
            ...event,
            date
          }
        },
      });
    },
    [updateEventQuery, calendarDate]
  );
  return (
    <Card>
      <Form
        initialState={item ? item : {}}
        fields={eventFields}
        onSubmit={handleSubmit}
        className='Events-form'
      />
    </Card>
  )
};

export default EventForm;
