import React, { useCallback, useState } from "react";
import {useQuery, useMutation, ApolloError} from "@apollo/client";
import {Card, Checkbox} from "ui-components";
import {Form, InputText} from "ui-form";

import { GET_HOTEL } from "../Hotel/hotel.queries";
import { getFields } from "../Hotel/hotel.formFields";
import {UPDATE_USERS} from "./Users.queries";

type UserHotelProps = {
  userId: string;
  userDetails: Record<string, any> | null;
  refetchList?: (variables?: any) => Promise<any>;
};

const UserHotel: React.FC<UserHotelProps> = ({
                                               userId,
                                               userDetails,
                                               refetchList
}) => {
  const [updateUserMutation] = useMutation(UPDATE_USERS, {
    onCompleted: () => {
      if(refetchList) {
        refetchList();
      }
    },
    onError: (error: ApolloError) => console.error(error),
  });
  const [updatedName, setUpdatedName] = useState<string>();
  const { data } = useQuery(GET_HOTEL, {
    variables: {
      id: userId,
    },
  });
  const changeNameHandler = useCallback((name: string, value: string) => {
    setUpdatedName(value);
  }, [])
  const blurNameHandler = useCallback(() => {
    if (!updatedName) {
      return;
    }
    updateUserMutation({
      variables: {
        user: {
          _id: userId,
          email: updatedName,
        },
      },
    });
  }, [updatedName, updateUserMutation, userId]);
  const handleDisableToggle = useCallback(() => {
    if (!userId || !userDetails) {
      return;
    }
    updateUserMutation({
      variables: {
        user: {
          _id: userId,
          isDisabled: !userDetails?.isDisabled,
        },
      },
    });
  }, [updateUserMutation, userDetails, userId])
  return (
    <Card pushDown style={{maxHeight: `${window.innerHeight - 140}px`, overflowY: 'scroll'}}>
      {data ? (
        data.hotel ? (
          <>
            <div className='Hotel-formHeader'>Viešbučio informacija</div>
            <div className='Hotel-formContent'>
              <InputText
                key='userName'
                name='userName'
                label='Prisijungimo vardas'
                value={updatedName || userDetails?.email}
                onChange={changeNameHandler}
                onBlur={blurNameHandler}
              />
              <div className='FormInput-label mb-xs'>
                Prisijungimas deaktyvuotas?
              </div>
              <div className='mb-m'>
                <Checkbox value={userDetails?.isDisabled} onToggle={handleDisableToggle} />
              </div>
              <Form
                initialState={data ? data.hotel : {}}
                fields={getFields(
                  data?.settings?.hotelTypes || [],
                  data?.settings?.serviceTypes || [],
                  data?.settings?.towns || []
                )}
              />
            </div>
          </>
        ) : (
          <div className='Hotel-formHeader'>
            Informacija apie viešbuti neužpildyta
          </div>
        )
      ) : null}
    </Card>
  );
};

export default UserHotel;
