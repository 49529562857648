import React, { useCallback, useState, useMemo } from 'react';
import { useLazyQuery, useQuery } from "@apollo/client";
import { Form } from "ui-form";
import { GET_HOTELS_LIST, GET_MY_HOTEL } from "../Hotel/hotel.queries";
import { getFields } from "../Dashboard/dashboard.formFields";
import HotelItem from './HotelItem';
import HotelDetails from './HotelDetails';
import './HotelsList.scss';

const initialState = {};
const today = new Date();

const HotelsList: React.FC = () => {
    const { data } = useQuery(GET_MY_HOTEL);
    const [selectedHotel, setSelectedHotel] = useState<(string)>('');
    const [year, setYear] = useState(today.getFullYear());
    const [getHotelsListQuery, { data: hotelsList }] = useLazyQuery(
      GET_HOTELS_LIST
    );

    const fields = useMemo(
        () =>
          getFields(
            data?.settings?.hotelTypes || [],
            data?.settings?.serviceTypes || [],
            data?.settings?.towns || []
          ),
        [data]
    );

    const hotelsOptions: any[] = useMemo(() => {
        return !hotelsList?.filteredHotels?.length
          ? []
          : hotelsList?.filteredHotels;
    }, [hotelsList]);

    const handleChange = useCallback(
        (filter) => {
          getHotelsListQuery({ variables: { filter } });
        },
        [getHotelsListQuery]
    );

    const hotelSelectHandler = useCallback((selected: string) => {
        setSelectedHotel(selected);
      }, []);

    const filterSubmitHandler = useCallback(() => {

    },[]);

    const yearChangeHandler = useCallback((year: string|number) => {
        setYear(typeof year === 'string' ? parseInt(year) : year);
    }, []);

    return (
        <div className='ph-l pb-l flexRow'>
            <div className='HotelsList-column'>
                <Form
                    initialState={initialState}
                    fields={fields}
                    onUpdate={handleChange}
                    onSubmit={filterSubmitHandler}
                    disabledSubmit={true}
                    hideSubmit={true}
                />
            </div>
            <div className='HotelsList-column'>
                <div className='mb-xs'>Viešbučių sąrašas</div>
                <div className='HotelsList-list MultiSelect'>
                    {hotelsOptions.map((hotel: any) =>
                        <HotelItem
                            key={hotel._id}
                            hotel={hotel}
                            onClick={hotelSelectHandler}
                            isSelected={hotel._id === selectedHotel}
                        />
                    )}
                </div>
            </div>
            <div className='HotelsList-column'>
                {selectedHotel !== '' ?
                    <HotelDetails
                        year={year}
                        hotelId={selectedHotel}
                        onYearChange={yearChangeHandler}
                    /> : null
                }
            </div>
        </div>
    );
}

export default HotelsList;
