import React, { useCallback } from "react";
import numeral from "numeral";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import { Card } from "ui-components";
import { DashboardChartType } from "./Dashboards.types";

const chartColors = ["#c00000", "#e1c400", "#216891", "#bd529e", "#f08200"];

const DashboardChart: React.FC<DashboardChartType> = ({
  label,
  chartWidth,
  hotelName,
  dataKey,
  dashboardFilters,
  chartData,
}) => {
  const formatValue = useCallback((value) => numeral(value).format("0.00"), [])
  return (
    <Card pushDown>
      <div className='Dashboard-chartHeader mb-s'>{label}</div>
      <LineChart
        width={chartWidth}
        height={250}
        data={chartData}
        margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' domain={['dataMin', 'dataMax']} />
        <YAxis />
        <Tooltip formatter={formatValue} />
        <Legend />
        <Line
          type='monotone'
          dataKey={dataKey}
          name={hotelName}
          stroke='#5D7C78'
        />
        {dashboardFilters.map((filter: any, index: number) => (
          <Line
            key={`occupancy_${index}`}
            type='monotone'
            dataKey={`${dataKey}_${index}`}
            name={`Filtras nr. ${index + 1}`}
            stroke={chartColors[index]}
          />
        ))}
      </LineChart>
    </Card>
  );
};

export default DashboardChart;
