import React, { useCallback } from "react";
import "./Input.scss";

type InputProps = {
  className?: string;
  value?: string | number;
  name?: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  onChange: (value: string, name?: string) => void;
  onFocus?: (name: string) => void;
  onBlur?: (name: string) => void;
  onEnterKey?: () => void;
};

export const Input: React.FC<InputProps> = ({
  className,
  onChange,
  onEnterKey,
  onFocus,
  onBlur,
  ...props
}) => {
  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.value, e.target.name);
    },
    [onChange]
  );
  const keyHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if(e.key === 'Enter'){
        onEnterKey && onEnterKey();
      }
    },
    [onEnterKey]
  )
  const focusHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onFocus && onFocus(e.target.name)
    },
    [onFocus]
  )
  const blurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(e.target.name)
    },
    [onBlur]
  )
  return (
    <input
      {...props}
      onChange={changeHandler}
      onFocus={focusHandler}
      onBlur={blurHandler}
      onKeyDown={keyHandler}
      className={`Input ${className || ''}`}
    />
  );
};
