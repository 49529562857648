import React, { useCallback } from "react";
import { Select, SelectOptionType } from "ui-components";

export type InputProps = {
  key?: string;
  label?: string;
  name: string;
  value: any;
  placeholder?: string;
  options?: SelectOptionType[];
  disabled?: boolean;
  onChange: (name: string, value: any) => void;
};

export const InputSelect: React.FC<InputProps> = ({
  label,
  name,
  value,
  options,
  disabled,
  onChange
}) => {
  const changeHandler = useCallback(
    (newValue: string | number) => onChange(name, newValue),
    [name, onChange]
  );

  return (
    <div className='FormInput mb-m'>
      {label ? <div className='FormInput-label mb-xs'>{label}</div> : null}
      <Select
        selected={value || ''}
        onChange={changeHandler}
        options={options || []}
        disabled={disabled}
      />
    </div>
  );
};
