import React, { useCallback } from "react";
import { useQuery, useMutation, ApolloError } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Card } from "ui-components";
import { Form } from "ui-form";
import { RouteLocation } from 'common/types';
import { getFields } from "./hotel.formFields";
import { GET_MY_HOTEL, GET_HOTEL_BY_ID, UPDATE_HOTEL } from "./hotel.queries";
import "./Hotel.scss";

const parseSearch = (query?: string) => {
  if (!query) {
    return {};
  }
  const q = query.replace('?', '');
  const splitted = q.split('&');
  return splitted.reduce((acc: Record<string, string>, item: string) => {
    const pair = item.split('=');
    return { ...acc, [pair[0]]: pair[1] }
  }, {});
}

const Hotel = () => {
  const { search } = useLocation<RouteLocation>();
  const searchParams = parseSearch(search);
  const { data } = 
    useQuery(searchParams?.id ? GET_HOTEL_BY_ID : GET_MY_HOTEL, { variables: { hotelId: searchParams?.id, id: '' }});
  const history = useHistory();
  const [updateHotelQuery] = useMutation(UPDATE_HOTEL, {
    onCompleted: () => {
      alert("Pakeitimai išsaugoti!");
      if (searchParams?.id) {
        history.goBack();
      }
    },
    onError: (error: ApolloError) => console.error(error),
  });

  const handleSubmit = useCallback(
    (data: Record<string, any>) => {
      const { owner, __typename, ...hotel } = data;
      updateHotelQuery({
        variables: {
          hotel: {
            ...hotel,
            numberOfRooms: hotel.numberOfRooms
              ? parseFloat(hotel.numberOfRooms)
              : null,
          }
        },
      });
    },
    [updateHotelQuery]
  );

  const handleHotelForm = useCallback(
    () => {
      history.push('/monthlyReports?uid=' + searchParams?.id);
    },
    [history, searchParams]
  );

  return (
    <div className='p-m Hotel'>
      <Card>
        <div className='Hotel-formHeader'>Viešbučio informacija</div>
        <div className='Hotel-formContent'>
          <Form
            initialState={data ? data.myHotel || data.hotel : {}}
            fields={getFields(
              data?.settings?.hotelTypes || [],
              data?.settings?.serviceTypes || [],
              data?.settings?.towns || []
            )}
            onSubmit={handleSubmit}
            onAdditionalButton={searchParams?.id ? handleHotelForm : undefined}
            additionalButtonName='Viešbučio duomenys'
          />
        </div>
      </Card>
    </div>
  );
};

export default Hotel;
