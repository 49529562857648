import React from "react";
import { useLocation } from "react-router-dom";
import { getNavigationList } from "../../../navigationSettings";
import NavBarHeader from "./NavBarHeader";
import NavBarFooter from "./NavBarFooter";
import NavBarItem from "./NavBarItem";
import "./NavBar.scss";

type UserType = {
  email: string;
  isAdmin: boolean;
};

type NavBarProps = {
  user: UserType
};

const NavBar: React.FC<NavBarProps> = ({ user }) => {
  const location = useLocation();
  const navigationList = getNavigationList(user);
  return (
    <div className='NavBar'>
      <NavBarHeader />
      {navigationList.map(({ path, label }) => (
        <NavBarItem
          key={path}
          path={path}
          label={label}
          selected={path === location.pathname}
        />
      ))}
      <NavBarFooter />
    </div>
  );
};

export default NavBar;
