export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getPeriodString = (year: number, month: number, addZero: boolean = true) =>
  `${year}-${addZero && month < 9 ? "0" : ""}${month + 1}`;

export const getChartYearMonths = (
  year: number,
  month: number,
  addZero?: boolean
) => {
  const result = [];
  for (let i = 0; i <= month; i++) {
    result.push(getPeriodString(year, i, addZero));
  }
  return result;
}

export const getChartMonths = (
  year: number,
  month: number,
  addZero?: boolean
) => {
  const arr = new Array(12).fill(undefined);
  return arr.map((val: any, index: number) => {
    const monthAdded = month + 1 + index;
    const yearOut = monthAdded < 12 ? year - 1 : year;
    const monthOut = monthAdded < 12 ? monthAdded : monthAdded - 12;
    return getPeriodString(yearOut, monthOut, addZero);
  });
};

export const mapByYearMonth = (arr: any[]) =>
  arr.reduce(
    (acc, item) => ({
      ...acc,
      [getPeriodString(item.year, item.month, true)]: item,
    }),
    {}
  );

  export const parseSearchParams = (searchString: string): Record<string, string> => {
    const pureSearch = searchString.replace('?', '');
    const paramsStrings = pureSearch.split('&');
    return paramsStrings.reduce((acc, paramString: string) => {
      const paramSplit: string[] = paramString.split('=');
      return { ...acc, [paramSplit[0]]: paramSplit[1] };
    }, {});
  }
