import React from "react";
import { RecoilRoot } from "recoil";
import { ApolloProvider } from '@apollo/client';
import MainRoute from "./MainRoute";
import apolloClient from './apolloClient';

import "./App.scss";

const App = () => (
  <RecoilRoot>
    <ApolloProvider client={apolloClient}>
      <MainRoute />
    </ApolloProvider>
  </RecoilRoot>
);

export default App;
