import React from "react";
import './Tooltip.scss';

type TooltipProps = {
  text: string;
  position?: "top" | "left" | "right" | "bottom";
};

export const Tooltip: React.FC<TooltipProps> = ({
  text,
  position,
  children,
}) => {
  return (
    <div className='Tooltip'>
      <span className={`Tooltip-text ${position === 'left' ? 'left' : ''}`}>
        {text}
      </span>
      {children}
    </div>
  );
};
