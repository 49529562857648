import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { tokenState } from 'state/session';
import Login from './views/Login';
import AppContainer from './common/ui-containers/AppContainer';

const MainRoute = () => {
  const [token] = useRecoilState(tokenState);

  return (
    <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" render={() => (
              !token ? (
                  <Redirect to="/login"/>
              ) : (
                  <AppContainer />
              )
          )}/>
        </Switch>
      </Router>
  )
}

export default MainRoute;
