import { gql } from "@apollo/client";

const SETTINGS_FRAGMENT = gql`
  fragment SettingsFragment on Settings {
    _id
    hotelTypes {
      type
      label
      disabled
      isDefault
    }
    serviceTypes {
      type
      label
      disabled
      isDefault
    }
    eventTypes {
      type
      label
      disabled
      isDefault
    }
    towns {
      type
      label
      disabled
      isDefault
    }
  }
`;

export const GET_SETTINGS = gql`
  query FetchSettings($showDisabled: Boolean) {
    settings(showDisabled: $showDisabled) {
      ...SettingsFragment
    }
  }
  ${SETTINGS_FRAGMENT}
`;

export const UPDATE_OPTION = gql`
  mutation UpdateSettingsOption($fieldName: String!, $updatedOptions: [inSettingsOptionType]!) {
    updateSettingsOption(fieldName: $fieldName, updatedOptions: $updatedOptions) {
      ...SettingsFragment
    }
  }
  ${SETTINGS_FRAGMENT}
`;
