import { SetterOrUpdater } from "recoil";
import moment from 'moment';
import { getUri } from 'network-settings';
import apolloClient from '../../apolloClient';

export const login = async (
  user: string,
  password: string,
  setToken: SetterOrUpdater<string>,
  historyPush: (path: string) => void
) => {
  const uriRoot = getUri();
  const response = await fetch(`${uriRoot}/authentication`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      strategy: "local",
      email: user,
      password,
    }),
  });

  const result = await response.json();
  if (result.accessToken) {
    handleLoginSuccess(result.accessToken, setToken, historyPush);
    return true;
  }
  return false;
};

export const verifyUser = async (
  token: string | undefined,
  password: string,
  historyReplace: (path: string) => void
) => {
  const uriRoot = getUri();
  const response = await fetch(`${uriRoot}/authmanagement`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: 'verifySignupSetPasswordLong',
      value: {
        token,
        password
      }
    }),
  });

  const result = await response.json();
  if (result.isVerified) {
    historyReplace(`/login?user=${result.email}`);
    return result.email;
  }
  alert(result.message);
  return '';
}

export const confirmResetPassword = async (
  token: string | undefined,
  password: string,
  historyReplace: (path: string) => void
) => {
  const uriRoot = getUri();
  const response = await fetch(`${uriRoot}/authmanagement`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: 'resetPwdLong',
      value: {
        token,
        password
      }
    }),
  });

  const result = await response.json();
  if (result.email) {
    historyReplace(`/login?user=${result.email}`);
    return result.email;
  }
  alert(result.message);
  return '';
}

export const resetPassword = async (email: string) => {
  const uriRoot = getUri();
  const response = await fetch(`${uriRoot}/authmanagement`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: 'sendResetPwd',
      value: {
        email
      }
    }),
  });

  const result = await response.json();
  console.log(result);
  if (result._id) {
    // alert('Nuoroda nustatyti naujam slaptažodžiui nusiųsta į jūsų el. paštą.')
    return true;
  }
  alert(`Naudotojas el. paštu ${email} nerastas sistemoje`);
}

const handleLoginSuccess = (
  token: string,
  setToken: SetterOrUpdater<string>,
  historyPush: (path: string) => void
) => {
  setToken(token);
  storeToken(token);
  historyPush("/");
};

const storeToken = (token: string) => {
  localStorage.setItem("token", token);
  localStorage.setItem("token_expire", moment().add(1, 'd').format())
}

const cleanAuthStorage = (): void => {
  localStorage.removeItem("token");
  localStorage.removeItem("token_expire");
}

const hasTokenExpired = (expiration: string | null): boolean => !expiration ? true : moment().isAfter(expiration);

export const getToken = (): string => {
  const token = localStorage.getItem("token");
  if (!token) {
    return '';
  }
  const expiration = localStorage.getItem("token_expire");
  const isExpired = hasTokenExpired(expiration);
  if (!isExpired) {
    return  token;
  }
  cleanAuthStorage();
  return '';
}

export const logout = (
  setToken: SetterOrUpdater<string>,
  historyPush: (path: string) => void
): void => {
  setToken("");
  cleanAuthStorage();
  historyPush("/login");
  apolloClient.clearStore();
};

export const validateEmail = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
