import React from "react";
import { SelectOptionType } from "../Select/Select";
import "./MultiSelect.scss";

type MultiSelectProps = {
  selected: (string | number)[];
  options: SelectOptionType[];
  disabled?: boolean;
  onChange: (value: (string | number)[]) => void;
};

export const MultiSelect: React.FC<MultiSelectProps> = ({
  selected,
  options,
  disabled,
  onChange,
}) => {
  const toggleOption = (option: string | number) => {
    if (disabled) {
      return;
    }
    const indexOfOption = selected.findIndex((item: string | number) => item === option);
    if (indexOfOption === -1) {
      onChange([...selected, option]);
    } else {
      onChange(
        selected.filter(
          (item: string | number, index: number) => index !== indexOfOption
        )
      );
    }
  };
  return (
    <div className='MultiSelect'>
      {options.map((option: SelectOptionType) => {
        const isSelected = selected.includes(option.name);
        return (
          <div
            key={option.name}
            className={`MultiSelect-option ${
              isSelected ? "MultiSelect-option--selected" : ""
            }`}
            onClick={() => toggleOption(option.name)}
          >
            {isSelected ? <span className='MultiSelect-optionCheckmark mr-xs'>✔</span> : null}
            {option.label}
          </div>
        );
      })}
    </div>
  );
};
