import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query FetchUsers {
    users {
      _id
      email
      isVerified
      isDisabled
      isAdmin
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation UpdateUser($user: inUser!) {
    updateUser(user: $user) {
      _id
      email
      isVerified
      isDisabled
      isAdmin
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser($id: String!) {
    removeUser(id: $id)
  }
`;

export const RESEND_VERIFICATION = gql`
  mutation ResendVerification($id: String!) {
    resendEmail(id: $id)
  }
`;
