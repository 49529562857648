import React, { useCallback, useState, useEffect } from "react";
import { Card } from "ui-components";
import DayHeader from "./EventsDayHeader";
import EventForm from "./EventForm";

type EventType = {
  type: string;
  name: string;
}

type EventsDayProps = {
  calendarDate: Date;
  timestamp: number;
  events?: Record<string, any>[]; // TODO: define Event records
  eventsRefetch: any;
  eventTypes?: EventType[]
};

const EventsDay: React.FC<EventsDayProps> = ({
  calendarDate,
  events,
  eventsRefetch,
  timestamp,
  eventTypes
}) => {
  const [values, setValues] = useState<Record<string, any>[]>([]);
  const [showAddBuuton, setShowAdd] = useState(true);

  const addClickHandler = useCallback(() => {
    setValues([...values, {}]);
    setShowAdd(false);
  }, [values]);

  useEffect(() => {
    setShowAdd(values.length === 0 || values[values.length - 1]._id);
  }, [values]);

  useEffect(() => {
    setValues(events || []);
  }, [events]);

  const submitHandler = useCallback(
    (item) => {
      eventsRefetch();
    },
    [eventsRefetch]
  );

  return (
    <div className='DataRegistration-forms'>
      <DayHeader day={calendarDate} />

      <div className='mt-m flexRow'>
        {values.map((value: Record<string, any>) => (
          <div className='mr-m' key={value._id || "new"}>
            <EventForm
              item={value}
              onSubmit={submitHandler}
              calendarDate={timestamp}
            />
          </div>
        ))}
        {showAddBuuton ? (
          <div className='Events-addButton' onClick={addClickHandler}>
            <Card>
              <div className='Events-dayHeader ph-xs'>+</div>
            </Card>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EventsDay;
