import React, { useState, useCallback, useEffect } from "react";
import { useMutation, ApolloError } from "@apollo/client";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { UilBars } from '@iconscout/react-unicons';
import { Card } from "ui-components";
import { reorder } from "common/utils";
import { settingsLabels } from "../../common/constants";
import { InputText } from "ui-form";
import { UPDATE_OPTION } from "./settings.gql";

export type OptionItemType = {
  type: string;
  label: string;
  disabled?: boolean;
  isDefault?: boolean;
  __typename?: string;
};

type SettingsOptionsCardProps = {
  name: string;
  options: OptionItemType[];
};

const getListStyle = (isDraggingOver: Boolean) => ({
  paddingBottom: isDraggingOver ? 54 : 0
});

const SettingsOptionsCard: React.FC<SettingsOptionsCardProps> = ({
  name,
  options,
}) => {
  const [optionsState, setOptionsState] = useState<OptionItemType[]>([]);
  const [newOption, setNewOption] = useState<string>("");
  const [updateSettingsQuery] = useMutation(UPDATE_OPTION, {
    // onCompleted: () => alert("Pakeitimai išsaugoti!"),
    onError: (error: ApolloError) => {
      console.error(error);
      alert("Įvyko klaida!!!");
    },
  });

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  const changeHandler = useCallback(
    (name: string, value: any) => {
      setOptionsState((state: OptionItemType[]) => {
        const changeIndex = parseInt(name);
        return state.map((option: OptionItemType, index: number) =>
          index !== changeIndex ? option : { ...option, label: value }
        );
      });
    },
    [setOptionsState]
  );

  const changeNewHandler = useCallback(
    (name: string, value: any) => {
      setNewOption(value);
    },
    [setNewOption]
  );

  const saveOptions = useCallback(
    (options: OptionItemType[]) => {
      updateSettingsQuery({
        variables: {
          fieldName: name,
          updatedOptions: options.map((option) => {
            const { __typename, ...clean } = option;
            return clean;
          }),
        },
      });
    },
    [name, updateSettingsQuery]
  );

  const newSubmitHandler = useCallback(() => {
    setOptionsState((state: OptionItemType[]) => {
      const updated = state
        ? [
            ...state,
            {
              type: newOption.toLowerCase().replace(/\s/g, ""),
              label: newOption,
            },
          ]
        : [
            {
              type: newOption.toLowerCase().replace(/\s/g, ""),
              label: newOption,
            },
          ];
      saveOptions(updated);
      return updated;
    });
    setNewOption("");
  }, [setOptionsState, setNewOption, saveOptions, newOption]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      setOptionsState((state: OptionItemType[]) => {
        const updated = reorder(
          state,
          result.source.index,
          result.destination.index
        );
        saveOptions(updated);
        return updated;
      });
    },
    [saveOptions]
  );

  return (
    <div className='SettingsOptionsCard'>
      <Card>
        <div className='SettingsOptionsCard-Header mb-m'>
          {settingsLabels[name]}
        </div>
        <div className='SettingsOptionsCard-content mb-s'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {optionsState
                    ? optionsState.map((option, index) => (
                        <Draggable
                          key={option.type}
                          draggableId={option.type}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className='flexRow'
                            >
                              <div className='pt-s mr-xs'>
                                <UilBars color="#868B92" />
                              </div>
                              <div className='SettingsOptionsCard-input'>
                                <InputText
                                  name={index.toString()}
                                  value={option.label}
                                  onChange={changeHandler}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className='pl-l'>
            <InputText
              key='new'
              name='new'
              value={newOption}
              placeholder='Naujas'
              onChange={changeNewHandler}
              onSubmit={newSubmitHandler}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SettingsOptionsCard;
