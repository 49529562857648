import React from "react";
import { InputProps } from "./InputText";
import "./StarsSelect.scss";

const starsList = ["★", "★★", "★★★", "★★★★", "★★★★★"];

type MultiSelect = {
  isMulti?: boolean;
}
const isSelected = (count: number, value: number[] | number, isMulti?: boolean) => 
  isMulti ? Array.isArray(value) && value.includes(count) : count === value;

export const StarsSelect: React.FC<InputProps & MultiSelect> = ({
  label,
  name,
  value,
  isMulti,
  disabled,
  onChange,
}) => {
  const starsClickHadle = (clickValue: number) => () => {
    if (clickValue === value || disabled) {
      return;
    }
    if (isMulti) {
      if (value && Array.isArray(value)) {
        const filtered = value.filter((item: any) => item !== clickValue);
        return onChange(name, filtered.length !== value.length ? filtered : [...value, clickValue])
      }
      return onChange(name, [clickValue]);
    }
    onChange(name, clickValue);
  };

  return (
    <div className='mb-m' key={name}>
      {label ? <div className='mb-xs'>{label}</div> : null}
      <div className='StarsSelect'>
        {starsList.map((label, index) => {
          const count = index + 1;
          return (
            <div
              key={`star-${count}`}
              className={`StarsSelect-item ${
                isSelected(count, value, isMulti) ? "StarsSelect-item--selected" : ""
              } mh-xs`}
              onClick={starsClickHadle(count)}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};
