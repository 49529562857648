import React from "react";
import {
  Dashboard,
  Hotel,
  Users,
  Settings,
  // DataInput,
  MonthlyReport,
  Events,
  HotelsList,
} from "./views";

type NavigationItemType = {
  label: string;
  path: string;
  component: React.FC;
  restrict?: string[];
};

type UserType = {
  isAdmin?: boolean;
};

const getRole = (user: UserType) =>
  !user ? "" : user.isAdmin ? "admin" : "hotel";

export const navigationList: NavigationItemType[] = [
  {
    label: "Suvestinė",
    path: "/dashboard",
    component: Dashboard,
  },
  {
    label: "Viešbutis",
    path: "/hotel",
    component: Hotel,
    restrict: ["hotel"],
  },
  // {
  //   label: "Duomenų formos",
  //   path: "/dataInput",
  //   component: DataInput,
  //   restrict: ["hotel"],
  // },
  {
    label: "Mėnesio ataskaitos",
    path: "/monthlyReports",
    component: MonthlyReport,
    restrict: ["hotel"],
  },
  {
    label: "Naudotojai",
    path: "/users",
    component: Users,
    restrict: ["admin"],
  },
  {
    label: "Viešbučiai",
    path: "/hotelsList",
    component: HotelsList,
    restrict: ["admin"],
  },
  {
    label: "Nustatymai",
    path: "/settings",
    component: Settings,
    restrict: ["admin"],
  },
  {
    label: "Renginiai",
    path: "/events",
    component: Events,
    restrict: ["admin"],
  },
];

export const getNavigationList = (user: UserType): NavigationItemType[] => {
  const role = getRole(user);
  return navigationList.filter(
    (item: NavigationItemType) => !item.restrict || item.restrict.includes(role)
  );
};
