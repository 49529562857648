import React, {CSSProperties} from "react";
import "./Card.scss";

type CardProps = {
  pushDown?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
};

export const Card: React.FC<CardProps> = ({
  children,
  onClick,
  className = "",
  style,
  pushDown = false,
}) => (
  <div
    className={`p-m Card Card--${pushDown ? "down" : "up"} ${className}`}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);
