import memoize from "./memo.util";
import { SelectOptionType, MONTHS } from 'ui-components';

export const getWeekNumber = (dt: Date): number => {
  const tdt = new Date(dt.valueOf());
  const dayn = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
};
type ParsedDateNumbers = {
  year: number;
  month: number;
  day: number;
};
export const getParsedDate = (
  year: number,
  month: number,
  day: number
): ParsedDateNumbers => {
  const dateObject = new Date(year, month, day);
  return {
    year: dateObject.getFullYear(),
    month: dateObject.getMonth(),
    day: dateObject.getDate(),
  };
};

type weeksInYearProps = {
  year: number;
};

export const getWeeksInYear = memoize((year: number): number => {
  const lastDayOfYear = new Date(year, 11, 31);
  const week = getWeekNumber(lastDayOfYear);
  return week === 1 ? 52 : week;
});

export const getMonthOfWeek = memoize((year: number, week: number): number => {
  const january4 = new Date(year, 0, 4);
  const weekdayJanuary4 = january4.getDay();
  const dayOfYear = week * 7 + 4 - (weekdayJanuary4 + 3);
  const dateOfSunday = new Date(year, 0, dayOfYear);
  return dateOfSunday.getMonth();
});

export const getMonthStart = memoize((day: Date) =>
  (new Date(day.getFullYear(), day.getMonth(), 1).getTime() / 1000)
);

export const getMonthEnd = memoize((day: Date) =>
  (new Date(day.getFullYear(), day.getMonth() + 1, 1).getTime() / 1000)
);

export const getStartOfDay = memoize((day: Date) => 
  new Date(day.getFullYear(), day.getMonth(), day.getDate())
);

export const getTimestamp = memoize((day: Date) => 
  (new Date(day.getFullYear(), day.getMonth(), day.getDate()).getTime() / 1000)
);

export const getDateFromTimestamp = memoize((timestamp: number) => new Date(timestamp * 1000));

export const getDateKey = (year: number, month: number, day: number) =>
  `${year}-${month}-${day}`;

export const getYearsArray = (before: number, after: number) => {
  const today = new Date();
  const thisYear = today.getFullYear();
  const startYear = thisYear - before;
  const yearRange = before + after + 1;
  const rangeArray = Array(yearRange).fill(0);
  return rangeArray.map((item: number, index: number) => startYear + index);
}

export const getLastDayOfMonth = (year: number, month: number): number => {
  const lastDay = new Date(year, month + 1, 0);
  return lastDay.getDate();
}

export const getYearsOptions = (maxYear: number, countBack: number) => {
  const options: SelectOptionType[] = [];
  for (let i = 0; i < countBack; i++) {
    const value = (maxYear - i);
    options.unshift({ name: value, label: value });
  }
  return options;
}

export const monthsOptions = MONTHS.map((name, index) => ({name: index, label: name}));

export const getPreviousPeriod = (year: number, month: number) => {
  if (month === 0) {
    return { year: year - 1, month: 11 };
  }
  return { year, month: month - 1 };
};

export const getDaysPerMonth = (year: number) => [
  31, (year % 4) === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31
];
