import React from 'react';
import HotelStars from './HotelStars';

type HotelItemType = {
    hotel: any;
    onClick: (id: string) => void;
    isSelected?: boolean;
}
const HotelItem: React.FC<HotelItemType> = ({ hotel, onClick, isSelected }) => (
    <div className='HotelItem MultiSelect-option' onClick={() => onClick(hotel._id)}>
        {isSelected ? <span className='MultiSelect-optionCheckmark mr-xs'>✔</span> : null}
        <div className='HotelItem-content'>
            <div className='HotelItem-name'>
                {hotel.name}
            </div>
            <div className='HotelItem-meta'>
                <span>{hotel.town}</span>
                {hotel.stars ? <HotelStars stars={hotel.stars} /> : null}
            </div>
        </div>
    </div>
);

export default React.memo(HotelItem);