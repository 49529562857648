function memoize<R, T extends (...args: any[]) => R>(f: T): T {
  const memory = new Map<string, R>();

  const g = (...args: any[]) => {
    const joinedArgs = args.join();
    if (!memory.get(joinedArgs)) {
        memory.set(joinedArgs, f(...args));
    }

    return memory.get(joinedArgs);
  };

  return g as T;
}

export default memoize;
