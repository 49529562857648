export const roomsColumn = [
  "total.rooms",
  "transient.rooms",
  "group.rooms",
  "contract.rooms",
];

export const revenueColumn = [
  "total.revenue",
  "transient.revenue",
  "group.revenue",
  "contract.revenue",
];

export const mappedColumns = [
  {name: 'total', rooms: roomsColumn[0], revenue: revenueColumn[0] },
  {name: 'transient', rooms: roomsColumn[1], revenue: revenueColumn[1] },
  {name: 'group', rooms: roomsColumn[2], revenue: revenueColumn[2] },
  {name: 'contract', rooms: roomsColumn[3], revenue: revenueColumn[3] },
]