import React, { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { Overlay, Avatar } from "ui-components";
import { useHistory } from "react-router-dom";
import { tokenState } from "state/session";
import { logout } from "../../../views/Login/loginUtils";
import { UserType } from "../uiContainerTypes";
import "./ProfileMenu.scss";

type ProfileMenuProps = {
  user: UserType;
  onClose: () => void;
};

const ProfileMenu: React.FC<ProfileMenuProps> = ({ user, onClose }) => {
  const setToken = useSetRecoilState(tokenState);
  const history = useHistory();
  const overlayClickHandler = useCallback(() => onClose(), [onClose]);
  const handleLogout = useCallback(() => logout(setToken, history.push), [
    history.push,
    setToken,
  ]);
  return (
    <>
      <Overlay onClick={overlayClickHandler} />
      <div className='ProfileMenu'>
        <div className='Profile-header'>
          <Avatar text={user?.hotel?.name || user?.email} size='large' />
          <div className='mt-s'>{user?.hotel?.name}</div>
        </div>
        {!user?.isAdmin ? (
          <div className='Profile-menuSection'>
            <div className='Profile-menuItem pv-s ph-m'>
              Viešbučio nustatymai
            </div>
          </div>
        ) : null}
        <div className='ProfileMenu-footer'>
          <div className='Profile-menuItem' onClick={handleLogout}>
            Atsijungti
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileMenu;
