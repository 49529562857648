import React, { useState, useEffect } from 'react';
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Select, Button, MONTHS } from 'ui-components';
// import { MONTHS } from 'common/ui-components';
import { getYearsOptions, getDaysPerMonth } from "common/utils"
import { GET_HOTEL_META } from "../Hotel/hotel.queries";
import HotelStars from './HotelStars';

type HotelDetails = {
    hotelId: string;
    year: number;
    onYearChange: (year: string|number) => void;
}

const HotelDetails: React.FC<HotelDetails> = ({ hotelId, year, onYearChange }) => {
    const today = new Date();
    const yearsOptions = getYearsOptions(today.getFullYear(), 3);
    const [daysPerMonth, setDaysPerMonth] = useState(getDaysPerMonth(year));
    const { data } = useQuery(GET_HOTEL_META, {
        variables: { hotelId, year, id: '' }
    });
    const history = useHistory();
    useEffect(() => {
        setDaysPerMonth(getDaysPerMonth(year));
    }, [year]);

    const handleEdit = () => {
        history.push(`/hotel?id=${hotelId}`);
    }

    return (
        <div>
            {data?.hotel ? (
                <div>
                    <div className='mb-xs'>Viešbučio informacija</div>
                    <div className='HotelsList-list MultiSelect'>
                        <div className='HotelItem MultiSelect-option'>
                            <div className='HotelDetails-info'>Tipas: </div><div>{data.hotel.type}</div>
                        </div>
                        <div className='HotelItem MultiSelect-option'>
                            <div className='HotelDetails-info'>Pavadinimas: </div><div>{data.hotel.name}</div>
                        </div>
                        <div className='HotelItem MultiSelect-option'>
                            <div className='HotelDetails-info'>Žvaigždutės: </div><div><HotelStars stars={data.hotel.stars} /></div>
                        </div>
                        <div className='HotelItem MultiSelect-option'>
                            <div className='HotelDetails-info'>Adresas: </div><div>{[data.hotel.address, data.hotel.town].join(', ')}</div>
                        </div>
                        <div className='HotelItem MultiSelect-option'>
                            <div className='HotelDetails-info'>Kambarių sk.: </div><div>{data.hotel.numberOfRooms}</div>
                        </div>
                        <div className='HotelItem MultiSelect-option'>
                            <div className='HotelDetails-info'>Administratorius: </div><div>{data.hotel.owner?.email}</div>
                        </div>
                    </div>
                    <div className='mb-xs mt-m'>
                        <Button color='primary' onClick={handleEdit}>Redaguoti</Button>
                    </div>
                    <div className='mb-xs mt-m'>Pateikti duomenys už dienas per mėn.</div>
                    <div className='mb-xs'>
                        <Select
                            options={yearsOptions}
                            selected={year}
                            onChange={onYearChange}
                        />
                    </div>
                    <div className='HotelsList-list MultiSelect'>
                        {MONTHS.map((month, index) => (
                            <div key={month} className='HotelItem MultiSelect-option'>
                                <div className='HotelDetails-info'>{`${month}:`}</div>
                                <div>{`${data.hotelReportsMeta?.months[index] || 0}/${daysPerMonth[index]}`}</div>
                            </div> 
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default HotelDetails;
