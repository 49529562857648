import React from 'react';
import { useQuery } from "@apollo/client";
import SettingsOptionsCard from './SettingsOptionsCard';
import { GET_SETTINGS } from './settings.gql'
import './Settings.scss';

const settingTypes = [ 'hotelTypes', 'serviceTypes', 'eventTypes', 'towns' ];

const Settings = () => {
  const { data } = useQuery(GET_SETTINGS, { variables: {showDisabled: true }});
  return (
    <div className='ph-l pt-m Settings'>
      {data  && data.settings ? 
        settingTypes.map((type: string) => (
          <div className='mr-m mb-m' key={type}>
            <SettingsOptionsCard name={type} options={data.settings[type]} />
          </div>
        )
      ) : null}
    </div>
  );
};

export default Settings;
