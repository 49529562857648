import React, { useState, useCallback } from "react";
import { Avatar } from 'ui-components';
import ProfileMenu from "../ProfileMenu";
import { UserType } from '../uiContainerTypes';

type HeaderControlsProps = {
  user: UserType;
};

const HeaderControls: React.FC<HeaderControlsProps> = ({ user }) => {
  const [profileMenuVisible, setProfileMenuVisible] = useState(false);
  const profileCloseHandler = useCallback(
    () => setProfileMenuVisible(false),
    []
  );
  const openProfileMenu = useCallback(() => setProfileMenuVisible(true), []);
  return (
    <>
      <div className='HeaderControls'>
        <div className='ml-s'>
          <Avatar text={user?.hotel?.name|| user?.email} onClick={openProfileMenu} withShadow={true} />
        </div>
      </div>
      {profileMenuVisible ? (
        <ProfileMenu user={user} onClose={profileCloseHandler} />
      ) : null}
    </>
  );
};

export default HeaderControls;
